import React from 'react'

const AppCard = ({ h1, h2, img1, img2 }) => {

    return (
        <div className='h-[500px] md:h-[600px] w-[300px] md:w-[350px] rounded-2xl relative md:hover:scale-105 duration-1000 overflow-hidden'>
            <img className='absolute rounded-2xl h-full w-full -z-10' src={img1}></img>
            <div className='flex flex-col p-8 custom-font text-white'>
                <div className='text-lg custom-font-light'>{h1}</div>
                <div className='text-2xl'>{h2}</div>
            </div>
            <img className='-z-20 px-10 py-5' src={img2}></img>
        </div>
    )
}

export default AppCard