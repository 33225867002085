import React, { useState, useEffect } from 'react'

//IMPORT COMPONENTS
import Navbar from '../../components/LandingBar'
import Footer from '../../components/Footer'

//IMPORT GALLERY
import TestimonialGallery from '../../components/gallerys/TestimonialGallery'
import AppGallery from '../../components/gallerys/AppGallery';

//IMPORT BUTTONS
import PrimaryButton from '../../components/buttons/PrimaryButton';

// IMPORT TAGS
import HeaderTag from '../../components/tags/HeaderTag';

import AppCardImage1 from '../../media/appcard-4.webp'
import AppCardImage2 from '../../media/appcard-1.webp'
import AppCardImage3 from '../../media/appcard-2.webp'
import AppCardImage4 from '../../media/appcard-3.webp'
import AppCardImage5 from '../../media/appcard-5.webp'

import CardImage1 from '../../media/appcardimage-1.png'
import CardImage2 from '../../media/appcardimage-2.png'
import CardImage3 from '../../media/appcardimage-3.png'
import CardImage4 from '../../media/appcardimage-4.png'
import CardImage5 from '../../media/appcardimage-5.png'
import CardImage6 from '../../media/appcardimage-6.png'
import CardImage7 from '../../media/appcardimage-7.png'
import CardImage8 from '../../media/appcardimage-8.png'
import CardImage9 from '../../media/appcardimage-9.png'

import CarPlan1 from '../../media/carplan-1.png'
import CarPlan2 from '../../media/carplan-2.png'
import CarPlan3 from '../../media/carplan-3.png'
import CarPlan4 from '../../media/carplan-4.png'

//IMPORT HEADERS
import HeaderBig from '../../components/headers/BigHeader'
import HeaderSmall from '../../components/headers/SmallHeader'
import SmallHeader from '../../components/headers/SmallHeader';

const Lixhium = () => {

    const [isHovered1, setHovered1] = useState(false);
    const [isHovered2, setHovered2] = useState(false);

    const [imageSrc, setImageSrc] = useState(CardImage8);

    useEffect(() => {
        const interval = setInterval(() => {
            setImageSrc((prevSrc) => (prevSrc === CardImage8 ? CardImage9 : CardImage8));
        }, 2000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const AppCards = [
        {
            h1: 'Güvenilir',
            h2: 'İstasyon Bilgileri',
            img1: CardImage4,
            img2: AppCardImage1,
        },
        {
            h1: 'Canlı',
            h2: 'Müsaitlik Durumu',
            img1: CardImage2,
            img2: AppCardImage2,
        },
        {
            h1: 'Akıllı',
            h2: 'Yol Planlama',
            img1: CardImage3,
            img2: AppCardImage3,
        },
        {
            h1: 'Kolay',
            h2: 'Şarj Başlatma',
            img1: CardImage1,
            img2: AppCardImage4,
        },
        {
            h1: 'Avantajlı',
            h2: 'Ödeme Sistemi',
            img1: CardImage5,
            img2: AppCardImage5,
        },
    ];

    return (
        <div className='overflow-x-hidden relative'>
            <div className='absolute w-full h-full -z-50 bg-[#F3F7F9]'></div>
            <Navbar />
            <div className='p-16'></div>
            <HeaderBig text={'Lixhium App'} />
            <HeaderSmall text={'EV Şarj Ekosistemi <br/> Tek Platformda'} />
            <div className='flex flex-col pt-5'>
                <div className='flex flex-col md:flex-row gap-5 justify-center text-sm md:text-lg'>
                    <a href="https://apps.apple.com/tr/app/lixhium/id1645154277" target="_blank">
                        <div className='flex justify-center'>
                            <PrimaryButton text={"App Store'dan indir"} />
                        </div>
                    </a>
                    <div className='hidden md:block border-2 border-[#CFFF04]'></div>
                    <a href="https://play.google.com/store/apps/details?id=com.lixhium.lixhiumproapp" target="_blank">
                        <div className='flex justify-center'>
                            <PrimaryButton text={"PlayStore'dan indir"} />
                        </div>
                    </a>
                </div>
            </div>
            <AppGallery AppCards={AppCards} />
            <div className='pt-5 md:py-10'>
                <HeaderSmall text={'Deneyimlerin paylaşıldığı güvenilir topluluk.'} />
            </div>

            <TestimonialGallery />
            <HeaderTag AppCards={AppCards[0]} />
            <div className=' w-full max-w-screen-lg m-auto flex flex-col md:flex-row p-5 py-10 md:p-10 items-center gap-5 md:gap-0 '>
                <div className='md:bg-white w-full h-full flex flex-col md:p-10 gap-2 rounded-l-2xl'>
                    <div className='md:text-2xl custom-font-bold'> Daha Fazla Bilgi.</div>
                    <div className='md:w-96 custom-font-light text-sm md:text-base'>Lixhium, entegre olduğu şarj ağı markalarının istasyonlarını canlı olarak takip eder ve oluşan big data sayesinde kullanıcıya interaktif anlamlandırılmış bilgi sağlar. 40.000’den fazla Lixhium kullanıcısının uygulama içi katkılarını ve şarj alışkanlıklarını takip ederek en verimli detaylandırılmış bilgi en sade şekilde sunulur.</div>
                </div>
                <div className='w-2/3 md:w-1/2 flex flex-col-reverse md:flex-row justify-center gap-2 relative'>
                    <div className='md:absolute md:transform md:-translate-x-3/4 md:top-5 bottom-0'>
                        <div className='bg-[#003B95] p-5 px-6 inline-block text-white rounded-2xl custom-font-light text-sm md:text-base'> 2023 yılında Lixhium’a <span className='text-[#CFFF04] custom-font-bold'>+4.000 adet</span> şarj istasyonu eklendi.</div>
                    </div>
                    <img className='w-72' src={CardImage1}></img>
                </div>
            </div>
            <HeaderTag AppCards={AppCards[1]} />
            <div className=' w-full max-w-screen-lg m-auto flex md:flex-row flex-col p-5 py-10 md:p-10 items-center gap-5 md:gap-0 '>

                <div className='md:bg-white w-full h-full flex flex-col md:p-10 gap-2 rounded-l-2xl'>
                    <div className='md:text-2xl custom-font-bold'> Zamandan Kazan</div>
                    <div className='md:w-96 custom-font-light text-sm md:text-base'>Lixhium Ağı’na entegre firmaların istasyonlarında canlı müsaitlik durumu görülebilir. Bu veriyi işleyen Lixhium algoritması, kullanıcılara istasyon müsaitlik / doluluk tahmini sunabilmektedir.</div>
                </div>

                <div className='w-2/3 md:w-1/2 flex flex-col-reverse md:flex-row justify-center gap-2 relative'>
                    <div className='md:absolute md:transform md:-translate-x-3/4 md:top-5 bottom-0'>
                        <div className='bg-[#003B95] p-5 px-6 inline-block text-white rounded-2xl custom-font-light text-sm md:text-base'>2023 yılında <span className='text-[#CFFF04] custom-font-bold'>32 adet</span> şarj ağı işletmesi Lixhium’a dahil oldu.</div>
                    </div>
                    <img className='w-72' src={CardImage2}></img>
                </div>
            </div>
            <HeaderTag AppCards={AppCards[2]} />
            <div className=' w-full max-w-screen-lg m-auto flex flex-col gap-5 md:gap-0 md:flex-row pt-10 p-5 pb-0 md:p-10 items-center '>
                <div className='md:bg-white w-full h-full flex flex-col md:p-10 gap-2 rounded-l-2xl'>
                    <div className='flex flex-row gap-2 custom-font-light'>
                        <div className='p-2 px-4 bg-[#003B95] text-white rounded-md text-xs'>Yüzlerce Model</div>
                        <div className='p-2 px-4 bg-[#003B95] text-white rounded-md text-xs'>Gerçek Tüketim Verisi</div>
                    </div>

                    <div className='md:text-2xl custom-font-bold md:w-96'> A noktasından B noktasına Lixhium Daima Yanınızda</div>
                    <div className='md:w-96 custom-font-light text-sm md:text-base'>EV sürücülerinin şarj endişesi olmadan yolculuk planlamalarına yardımcı olmak için araçlarına uygun kapsamlı bir menzil, yol ve şarj planlama motoru sağlıyoruz.</div>
                </div>
                <div className='w-2/3 md:w-1/2 flex md:flex-row flex-col-reverse justify-center gap-2 relative'>
                    <div className='md:absolute md:transform md:-translate-x-3/4 md:top-5 bottom-0'>
                        <div className='bg-[#003B95] p-5 px-6 inline-block text-white rounded-2xl custom-font-light text-sm md:text-base'>2023’te <span className='text-[#CFFF04] custom-font-bold'>33.000 yeni kullanıcı</span> Lixhium’u tercih etti.</div>
                    </div>
                    <img className='w-72' src={CardImage6}></img>
                </div>
            </div>
            <div className=' w-full max-w-screen-lg m-auto flex flex-col p-10 pt-0 items-center'>
                <div className='py-10 md:p-10'>
                    <SmallHeader text={"Türkiye'nin elektrikli araç veri tabanı."} />
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4 md:pb-10'>
                    <div className='md:col-span-1 hover:scale-105 duration-500'>
                        <img className='' src={CarPlan1}></img>
                    </div>
                    <div className='md:col-span-1 hover:scale-105 duration-500'>
                        <img className='' src={CarPlan2}></img>
                    </div>
                    <div className='md:col-span-1 hover:scale-105 duration-500'>
                        <img className='' src={CarPlan3}></img>
                    </div>
                    <div className='md:col-span-1 hover:scale-105 duration-500'>
                        <img className='' src={CarPlan4}></img>
                    </div>
                </div>
            </div>
            <HeaderTag AppCards={AppCards[3]} />
            <div className='w-full max-w-screen-lg m-auto flex flex-col md:flex-row p-5 py-10 md:p-10 gap-5 md:gap-0 items-center '>
                <div className='md:bg-white w-full h-full flex flex-col md:p-10 gap-2 rounded-l-2xl'>
                    <div className='md:text-2xl custom-font-bold'> Daha Basit. Daha Hızlı.</div>
                    <div className='md:w-96 custom-font-light text-sm md:text-base'>Gerçek elektrikli araç kullanıcılarının taleplerine göre tasarlanan basit şarj başlatma ve şarj işlemi takibi arayüzleriyle fark yaratan bir emobilite deneyimi yaşayın.</div>
                </div>
                <div className='w-1/2 flex md:flex-row justify-center gap-2 relative'>
                    <div className={`hidden md:absolute md:transform md:-translate-x-3/4 md:top-5 bottom-0 duration-500 ${isHovered1 ? '-translate-x-80 shadow-md' : ''}`}>
                        <div className='bg-[#003B95] p-5 px-6 inline-block text-white rounded-2xl custom-font-light text-sm md:text-base'>2023’te Lixhium’da <span className='text-[#CFFF04] custom-font-bold'>1.818 adet</span> check-in yapıldı.</div>
                    </div>
                    <img
                        className={`w-72 duration-500`}
                        src={CardImage4}
                        alt='Card Image 4'
                        onMouseEnter={() => setHovered1(true)}
                        onMouseLeave={() => setHovered1(false)}
                    />
                    <img
                        className={`w-72 md:absolute md:transform md:translate-x-3/4 duration-500 ${isHovered1 ? 'ml-20' : ''}`}
                        src={CardImage7}
                        alt='Card Image 7'
                        onMouseEnter={() => setHovered2(true)}
                        onMouseLeave={() => setHovered2(false)}
                    />
                </div>
            </div>
            <HeaderTag AppCards={AppCards[4]} />
            <div className=' w-full max-w-screen-lg m-auto flex flex-col gap-5 md:flex-row p-5 py-10 md:p-10 items-center '>
                <div className='md:bg-white w-full h-full flex flex-col md:p-10 gap-2 relative rounded-l-2xl'>
                    <div className='md:text-2xl custom-font-bold'> Kolay Ödeme Seçenekleri</div>
                    <div className='md:w-96 custom-font-light text-sm md:text-base'>Lixhium, şarj ekosistemine özel kurgulanmış dijital cüzdanı cashback ve kampanya olanakları; geniş sadakat ağı, basit ve hızlı ödeme seçenekleriyle elektrikli araç kullanıcısının ihtiyaçlarına cevap verir. Lixhium’da yapılan her şarj işlemi kullanıcıya puan kazandırır.</div>
                    <div className='md:absolute -bottom-20 right-20 z-10 pt-10 md:pt-0'>
                        <div className='bg-[#003B95] p-5 px-6 inline-block text-white rounded-2xl custom-font-light text-sm md:text-base'>2023’te Lixhium’da ayda ortalama <span className='text-[#CFFF04] custom-font-bold'>40.000 şarj</span> işlemi desteklendi.</div>
                    </div>
                </div>
                <div className='w-1/2 flex flex-row justify-center gap-2 relative'>
                    <img
                        className={`w-72 duration-500`}
                        src={CardImage5}
                        alt='Card Image 4'
                    />
                    <img
                        className={`w-72 `}
                        src={imageSrc}
                        alt='Card Image 7'
                    />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Lixhium