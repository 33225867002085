import React from 'react'

const LinkedBlogCard = ({ item }) => {
    return (
        <div className='h-full w-full flex flex-col justify-between rounded-xl md:shadow-xl py-5'>
            <div className='flex flex-row gap-2 px-5'>
                <div className='rounded-full flex justify-center items-center'>
                    <img className='rounded-full w-24' src='https://media.licdn.com/dms/image/D4D03AQHNcmJMksSehA/profile-displayphoto-shrink_800_800/0/1696604602832?e=1715212800&v=beta&t=7L2bQLiRL94Jc_z8U8YwL6x5dXUtR1KLTCHm89QIHOU'></img>
                </div>
                <div className='flex flex-col justify-center'>
                    <div className='custom-font'>Berke Astarcıoğlu</div>
                    <div className='custom-font-light text-xs'>Co-Founder & CEO at Lixhium | e-Mobility Ecosystem | EV Charging Consultant</div>
                </div>
            </div>
            <div className='p-5 custom-font-light text-sm'>
                {item.text}
            </div>
            <div className='p-5 flex flex-row gap-5'>
                <img src={item.img[0]} className='h-32 w-32 object-cover'></img>
                <img src={item.img[1]} className='h-32 w-32 object-cover'></img>
            </div>
            <div className='px-5 custom-font-light cursor-pointer text-xs text-end'>Devamını Oku... </div>
        </div>
    )
}

export default LinkedBlogCard