import React from 'react';

const TestimonialCard = ({ name, carImage, carText, text, image, date }) => {
    return (
        <div className='relative duration-500 overflow-hidden rounded-2xl'>
            <div className='w-[300px] md:w-[600px] flex flex-row py-10'>
                <div className='bg-white w-full h-80 rounded-2xl p-4 flex flex-col justify-between'>
                    <div className='flex flex-col gap-2'>
                        <div className='flex flex-row gap-2'>
                            <div className='w-14 h-14 md:h-20 md:w-20 bg-white rounded-full flex justify-center items-center shrink-0 border-2 border-slate-400'>
                                <img className='h-14 w-14 object-contain' src={carImage}></img>
                            </div>
                            <div className='flex flex-col'>
                                <div className='text-md md:text-lg custom-font'>{name}</div>
                                <div className='text-xs custom-font-light text-slate-600'>{carText}</div>
                            </div>
                        </div>
                        <div className='text-xs md:text-sm custom-font-light'>{text}</div>
                        <div className='flex flex-col '>
                            {Array.isArray(image) && image.length > 0 && (
                                <div className='w-full flex flex-row flex-wrap gap-2'>
                                    {image.map((img, index) => (
                                        <img key={index} className='rounded-md md:rounded-2xl w-24 md:w-36 h-16 md:h-24 object-cover' src={img}></img>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='w-full flex justify-end text-slate-600 custom-font-light text-xs md:text-sm'>{date}</div>
                </div>
            </div>
        </div>
    );
};

export default TestimonialCard;
