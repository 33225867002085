import React from 'react'

//IMPORT COMPONENTS
import Navbar from '../../components/LandingBar'
import Footer from '../../components/Footer'

//IMPORT BUTTON
import PrimaryButton from '../../components/buttons/PrimaryButton'

//IMPORT IMAGE
import AdsPhoto1 from '../../media/ads-photo-1.png'
import AdsPhoto2 from '../../media/ads-photo-2.png'
import AdsPhoto3 from '../../media/ads-photo-3.png'
import AdsPhoto4 from '../../media/ads-photo-4.svg'
import AdsPhoto5 from '../../media/ads-photo-5.png'
import AdsPhoto6 from '../../media/ads-photo-6.png'
import AdsPhoto7 from '../../media/ads-photo-7.png'
import AdsPhoto8 from '../../media/ads-photo-8.png'
import AdsPhoto2_1 from '../../media/ads-photo-2-1.png'
import AdsPhoto2_2 from '../../media/ads-photo-2-2.png'
import AdsPhoto2_3 from '../../media/ads-photo-2-3.png'

//IMPORT HEADERS
import BigHeader from '../../components/headers/BigHeader'
import SmallHeader from '../../components/headers/SmallHeader'

const Ads = () => {
    const handleMailSend = () => {
        const emailAddress = 'info@lixhium.com';
        window.location.href = `mailto:${emailAddress}`;
    }
    return (
        <div className='relative'>
            <div className='absolute w-full -z-50 bg-[#F3F7F9] h-full'></div>
            <Navbar />
            <div className='p-16'></div>
            <BigHeader text={'Reklam & Tanıtım'} />
            <SmallHeader text={'İşletmenizin bilinirliğini artırın.'} />
            <div onClick={() => handleMailSend()} className='py-5 md:pt-5 flex justify-center'>
                <PrimaryButton text={'Teklif Alın'} />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 max-w-screen-xl m-auto">
                <div className="bg-white border-2 border-[#F3F7F9] flex flex-col md:rounded-2xl md:shadow-lg p-5">
                    <div className='p-5 pb-0'>
                        <div className='text-2xl custom-font-bold'>Kampanyalar</div>
                        <div className='text-lg md:text-xl custom-font-light'>Pazarlama faaliyetlerinizi yönetin.</div>
                    </div>
                    <div className='flex flex-grow justify-center pt-5 md:pt-0'>
                        <img className='object-contain block w-48 h-full' src={AdsPhoto1} alt="Ads Photo" />
                    </div>
                </div>
                <div className="bg-white border-2 border-[#F3F7F9] flex flex-col md:rounded-2xl md:shadow-lg p-5">
                    <div className='p-5'>
                        <div className='text-2xl custom-font-bold'>Ana Sayfa Banner Alanı</div>
                        <div className='text-lg md:text-xl custom-font-light'>İşletmenizi ideal vitrine taşıyın.</div>
                    </div>
                    <div className='flex flex-grow flex-col items-center md:flex-row p-5 md:pt-0 gap-5'>
                        <img className='object-contain block w-48 h-full' src={AdsPhoto2} alt="Ads Photo" />
                        <div className='h-full flex-grow flex flex-col gap-5 px-5 justify-center'>
                            <img src={AdsPhoto2_1} className='w-full rounded-2xl'></img>
                            <img src={AdsPhoto2_2} className='w-full rounded-2xl'></img>
                            <img src={AdsPhoto2_3} className='w-full rounded-2xl'></img>
                        </div>
                    </div>
                </div>
                <div className="bg-white border-2 border-[#F3F7F9] flex flex-col md:rounded-2xl md:shadow-lg p-5">
                    <div className='p-5'>
                        <div className='text-2xl custom-font-bold'>Yol Planlama Banner Alanı</div>
                        <div className='text-lg md:text-xl custom-font-light'>Tercih edilebilirliğinizi artırın.</div>
                    </div>
                    <div className=' flex flex-grow justify-center p-5'>
                        <img className='object-contain block w-48 h-full' src={AdsPhoto3} alt="Ads Photo" />
                    </div>
                </div>
                <div className="bg-white border-2 border-[#F3F7F9] flex flex-col md:rounded-2xl md:shadow-lg p-5">
                    <div className='p-5'>
                        <div className='text-2xl custom-font-bold'>Öne Çıkarmalar</div>
                        <div className='text-lg md:text-xl custom-font-light'>Görünürlüğünüzü artırın.</div>
                    </div>
                    <div className=' flex flex-grow justify-center pt-5 md:pt-0'>
                        <img className='object-contain block w-24 md:w-36 h-full' src={AdsPhoto4} alt="Ads Photo" />
                    </div>
                </div>
            </div>
            <div className='py-10'>
                <div className='text-3xl md:text-6xl font-bold md:pt-10 pb-2 text-center max-w-4xl m-auto text-[#0C0A3E] custom-font-bold'>Ek Hizmetler</div>
                <div className='text-2xl md:text-4xl font-bold text-center max-w-4xl m-auto text-[#0C0A3E] pb-2 px-5 custom-font-light'>Müşterinizin konforunu artırın.</div>
                <div onClick={() => handleMailSend()} className='py-5 md:pt-5 flex justify-center'>
                    <PrimaryButton text={'Teklif Alın'} />
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 max-w-screen-xl m-auto">
                <div className="bg-white border-2 border-[#F3F7F9] flex flex-col md:rounded-2xl md:shadow-lg p-5">
                    <div className='p-5 pb-0'>
                        <div className='text-2xl custom-font-bold'>Hotspot İnternet</div>
                        <div className='text-lg md:text-xl custom-font-light'>Şarj istasyonlarınızda internet hizmeti verin.</div>
                    </div>
                    <div className='flex flex-grow justify-center pt-5 md:pt-0'>
                        <img className='object-contain block w-52 h-full' src={AdsPhoto5} alt="Ads Photo" />
                    </div>
                </div>
                <div className="bg-white border-2 border-[#F3F7F9] flex flex-col md:rounded-2xl md:shadow-lg p-5">
                    <div className='p-5'>
                        <div className='text-2xl custom-font-bold'>Akıllı Otomat Makinesi</div>
                        <div className='text-lg md:text-xl custom-font-light'>Müşterilerinize hızlı atıştırmalıklar sunun</div>
                    </div>
                    <div className='flex flex-grow justify-center pt-5 md:pt-0'>
                        <img className='object-contain block w-52 h-full' src={AdsPhoto6} alt="Ads Photo" />
                    </div>
                </div>
                <div className="bg-white border-2 border-[#F3F7F9] flex flex-col md:rounded-2xl md:shadow-lg p-5">
                    <div className='p-5'>
                        <div className='text-2xl custom-font-bold'>Profesyonel Bariyer Sistemi</div>
                        <div className='text-lg md:text-xl custom-font-light'>İstasyonlarınızın şarj alanlarını koruyun.</div>
                    </div>
                    <div className=' flex flex-grow justify-center pt-5 md:pt-0'>
                        <img className='object-contain block w-24 md:w-44 h-full' src={AdsPhoto7} alt="Ads Photo" />
                    </div>
                </div>
                <div className="bg-white border-2 border-[#F3F7F9] flex flex-col md:rounded-2xl md:shadow-lg p-5">
                    <div className='p-5'>
                        <div className='text-2xl custom-font-bold'>Sponsorlu Güzergahlar</div>
                        <div className='text-lg md:text-xl custom-font-light'>Tercih edilebilirliğinizi artırın.</div>
                    </div>
                    <div className=' flex flex-grow justify-center pt-5 md:pt-0'>
                        <img className='object-contain block scale-90 h-full' src={AdsPhoto8} alt="Ads Photo" />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Ads