import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { AiOutlineDown, AiOutlineMenu, AiFillApi, AiFillPhone, AiFillApple, AiFillInstagram, AiFillCaretDown } from 'react-icons/ai'
import { MdLocationOn } from 'react-icons/md'
import { GrMail } from 'react-icons/gr'
import { FaGooglePlay } from 'react-icons/fa'
import { BsLinkedin, BsTwitter, BsFillLightningChargeFill } from 'react-icons/bs'

// IMPORT LOGO
import AppleLogo from '../media/apple-logo-custom.svg'
import PlayLogo from '../media/play-logo-custom.svg'
import LixhiumLogo from '../media/lixhium-logo-black.svg'
import ApiLogo from '../media/api-logo-black.svg'
import ChargerLogo from '../media/charger-logo-black.svg'
import TruckLogo from '../media/truck-logo-3-black.svg'
import CarLogo from '../media/car-logo-2-black.svg'
import PhoneLogo from '../media/phone-logo-black.svg'
import MailLogo from '../media/mail-logo-black.svg'
import LocationLogo from '../media/location-logo-black.svg'
import ServiceLogo1 from '../media/service-1.svg'
import ServiceLogo2 from '../media/service-2.svg'
import ServiceLogo3 from '../media/service-3.svg'

// IMPORT BUTTON
import PrimaryButton from './buttons/PrimaryButton';
import SecondaryButton from '../components/buttons/SecondaryButton';
import MenuDropdown from './dropdowns/MenuDropdown';

import { Squash as Hamburger } from 'hamburger-react'

const Navbar = ({ currentSection, screenHeight }) => {

    const navigate = useNavigate();
    const [menu, setMenu] = useState(false)
    const [desktopMenu, setDesktopMenu] = useState(false)
    const [mobileMenu, setMobileMenu] = useState(false)
    const [product, setProduct] = useState(false)
    const [solution, setSolution] = useState(false)
    const [service, setService] = useState(false)
    const [downloads, setDownloads] = useState(false)
    const [contacts, setContacts] = useState(false)

    const handlePhoneCall = () => {
        const phoneNumber = '+90 850 749 99 44';
        window.location.href = `tel:${phoneNumber}`;
    }

    const handleMailSend = () => {
        const emailAddress = 'info@lixhium.com';
        window.location.href = `mailto:${emailAddress}`;
    }

    const handleLocationFind = () => {
        window.open(
            'https://www.google.com/maps?ll=40.790344,29.511099&z=16&t=m&hl=tr&gl=TR&mapclient=embed&cid=4881880894948552485',
            '_blank'
        );
    }

    const handleOpenLink = (link) => {
        window.open(link, '_blank');
    }

    const handleAppDownload = () => {
        window.open('https://apps.apple.com/tr/app/lixhium/id1645154277', '_blank');
    }

    const handlePlayDownload = () => {
        window.open('https://play.google.com/store/apps/details?id=com.lixhium.lixhiumproapp', '_blank');
    }

    const handleMouseEnterProduct = () => {
        setSolution(false)
        setProduct(true)
        setService(false)
        setDesktopMenu(true)
    }

    const handleMouseEnterSolution = () => {
        setProduct(false)
        setSolution(true)
        setService(false)
        setDesktopMenu(true)
    }

    const handleMouseEnterService = () => {
        setProduct(false)
        setSolution(false)
        setService(true)
        setDesktopMenu(true)
    }

    const handleLinkClick = () => {
        window.scrollTo(0, 0);
    };

    const productArray = [
        {
            image: LixhiumLogo,
            title: 'Lixhium App',
            text: 'EV Sürücü Uygulaması'

        },
        {
            image: ApiLogo,
            title: 'API',
            text: 'Akıllı Şarj Altyapısı'
        }
    ]

    const solutionArray = [
        {
            image: ChargerLogo,
            title: 'CPO & eMSP',
            text: 'Sarj Ağı İşletmeleri İçin'

        },
        {
            image: TruckLogo,
            title: 'Filolar',
            text: 'Tek Bir Yerden Yönetim'

        },
        {
            image: CarLogo,
            title: 'Otomotiv',
            text: 'Elektrikli Araç Üreticileri İçin'

        }
    ]

    const serviceArray = [
        {
            image: ServiceLogo1,
            title: 'Danışmanlık',
            text: 'Sektörel Danışmanlık'

        },
        {
            image: ServiceLogo2,
            title: 'Raporlama',
            text: 'Stratejik Raporlama'

        },
        {
            image: ServiceLogo3,
            title: 'Reklam ve Hizmetler',
            text: 'Hedef Kitleye Ulaş'
        }
    ]

    const navbarArray = [
        <div className={`${product ? '' : 'hidden'} hidden md:flex flex-row max-w-screen-2xl m-auto justify-center h-full`}>
            <div className='flex flex-row gap-5 items-center'>
                <Link to={'/products/lixhium'} onClick={handleLinkClick}>
                    <div className='m-auto hover:cursor-pointer '>
                        <SecondaryButton image={productArray[0].image} title={productArray[0].title} text={productArray[0].text} />
                    </div>
                </Link>
                <div className='bg-[#CFFF04] w-1 h-10 rounded-full m-auto'></div>
                <Link to={'/products/api'} onClick={handleLinkClick}>
                    <div className='m-auto hover:cursor-pointer'>
                        <SecondaryButton image={productArray[1].image} title={productArray[1].title} text={productArray[1].text} />
                    </div>
                </Link>
            </div>
        </div>,

        <div className={`${solution ? '' : 'hidden'} hidden md:flex flex-row max-w-screen-2xl m-auto justify-center h-full`}>
            <div className='flex flex-row gap-5 items-center'>
                <Link to={'/solutions/cpo&emsp'} onClick={handleLinkClick}>
                    <div className='m-auto hover:cursor-pointer'>
                        <SecondaryButton image={solutionArray[0].image} title={solutionArray[0].title} text={solutionArray[0].text} />
                    </div>
                </Link>
                <div className='bg-[#CFFF04] w-1 h-10 rounded-full m-auto'></div>
                <Link to={'/solutions/fleets'} onClick={handleLinkClick}>
                    <div className='m-auto hover:cursor-pointer'>
                        <SecondaryButton image={solutionArray[1].image} title={solutionArray[1].title} text={solutionArray[1].text} />
                    </div>
                </Link>
                <div className='bg-[#CFFF04] w-1 h-10 rounded-full m-auto'></div>
                <Link to={'/solutions/automobiles'} onClick={handleLinkClick}>
                    <div className='m-auto hover:cursor-pointer'>
                        <SecondaryButton image={solutionArray[2].image} title={solutionArray[2].title} text={solutionArray[2].text} />
                    </div>
                </Link>
            </div>
        </div>,

        <div className={`${service ? '' : 'hidden'} hidden md:flex flex-row max-w-screen-2xl m-auto justify-center h-full`}>
            <div className='flex flex-row gap-5 items-center'>
                <Link to={'/services/consult'} onClick={handleLinkClick}>
                    <div className='m-auto hover:cursor-pointer'>
                        <SecondaryButton image={serviceArray[0].image} title={serviceArray[0].title} text={serviceArray[0].text} />
                    </div>
                </Link>
                <div className='bg-[#CFFF04] w-1 h-10 rounded-full m-auto'></div>
                <Link to={'/services/report'} onClick={handleLinkClick}>
                    <div className='m-auto hover:cursor-pointer'>
                        <SecondaryButton image={serviceArray[1].image} title={serviceArray[1].title} text={serviceArray[1].text} />
                    </div>
                </Link>
                <div className='bg-[#CFFF04] w-1 h-10 rounded-full m-auto'></div>
                <Link to={'/services/ads'} onClick={handleLinkClick}>
                    <div className='m-auto hover:cursor-pointer'>
                        <SecondaryButton image={serviceArray[2].image} title={serviceArray[2].title} text={serviceArray[2].text} />
                    </div>
                </Link>
            </div>
        </div>
    ]

    return (
        <div onMouseLeave={() => { setDesktopMenu(false); setProduct(false); setSolution(false); setService(false) }} className='fixed top-0 w-full z-50 bg-white'>
            {/* NAVBAR */}
            <div className={`${currentSection === 0 ? 'bg-none' : ''} ${desktopMenu ? '' : ''} h-[80px] w-full flex flex-row justify-between items-center px-5 md:px-10 custom-font-medium`}>
                {/* LOGO */}
                {/* <img className='w-10 h-10 bg-[#CFFF04] rounded-full p-2' src={LixhiumLogo} alt='lixhium-logo'></img> */}
                <Link to={'/'}>
                    <div className='text-2xl font-bold text-[#0C0A3E] cursor-pointer custom-font-bold'>Lixhium</div>
                </Link>
                {/* PAGE BUTTONS */}
                <div className='hidden md:flex flex-row gap-5'>
                    <div onMouseEnter={() => handleMouseEnterProduct()} className={`${product ? 'bg-[#CFFF04]' : ''}`} >
                        <PrimaryButton text={'Ürünler'} />
                    </div>
                    <div onMouseEnter={() => handleMouseEnterSolution()} className={`${solution ? 'bg-[#CFFF04]' : ''}`}>
                        <PrimaryButton text={'Çözümler'} />
                    </div>
                    <div onMouseEnter={() => handleMouseEnterService()} className={`${service ? 'bg-[#CFFF04]' : ''}`}>
                        <PrimaryButton text={'Hizmetler'} />
                    </div>
                    <Link to={'/blogs'}>
                        <div >
                            <PrimaryButton text={'Blog'} />
                        </div>
                    </Link>
                </div>
                {/* DOWNLOAD APP BUTTONS*/}
                <div className='hidden md:flex flex-row'>
                    <a href="https://play.google.com/store/apps/details?id=com.lixhium.lixhiumproapp" target="_blank">
                        <div className='group px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                            <FaGooglePlay size={25} className='text-[#0C0A3E] duration-500 ' />
                        </div>
                    </a>
                    <a href="https://apps.apple.com/tr/app/lixhium/id1645154277" target="_blank">
                        <div className='group px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                            <AiFillApple size={25} className='text-[#0C0A3E] duration-500' />
                        </div>
                    </a>
                </div>
                {/* MOBILE MENU BUTTON*/}
                <div onClick={() => setMobileMenu(!mobileMenu)} className='block md:hidden '>
                    <Hamburger size={25} />
                </div>
            </div>
            {/* DESKTOP DROWNDOWN */}
            <div className={`${desktopMenu ? 'h-[80px]' : 'h-0'} duration-500 w-full bg-white z-10 border-b-2 border-[#CFFF04]`}>
                {/* PRODUCT PAGE BUTTONS */}
                {product ? navbarArray[0] : null}
                {solution ? navbarArray[1] : null}
                {service ? navbarArray[2] : null}
                {/* CONTACT BUTTONS */}
                <div className={`${desktopMenu ? 'opacity-100 border-b-2 border-[#CFFF04]' : 'opacity-0'} flex flex-row justify-between pb-5 px-5 md:px-10 bg-white`}>
                    <div className={`hidden md:flex flex-row `}>
                        <a href="https://www.instagram.com/lixhium.network/" target="_blank">
                            <div className='group px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                                <AiFillInstagram size={20} className='text-[#0C0A3E] duration-500' />
                            </div>
                        </a>
                        <a href="https://twitter.com/lixhiumnetwork" target="_blank">
                            <div className='group px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                                <BsTwitter size={20} className='text-[#0C0A3E] duration-500' />
                            </div>
                        </a>
                        <a href="https://www.linkedin.com/company/lixhium/" target="_blank">
                            <div className='group px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                                <BsLinkedin size={20} className='text-[#0C0A3E] duration-500' />
                            </div>
                        </a>
                    </div>
                    <div className={`hidden md:flex flex-row`}>
                        <a href="tel:+90 850 749 99 44" target="_blank">
                            <div className='group px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                                <AiFillPhone size={20} className='text-[#0C0A3E] duration-500' />
                            </div>
                        </a>
                        <a href="https://www.google.com/maps?ll=40.790344,29.511099&z=16&t=m&hl=tr&gl=TR&mapclient=embed&cid=4881880894948552485" target="_blank">
                            <div className='group px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                                <MdLocationOn size={20} className='text-[#0C0A3E] duration-500' />
                            </div>
                        </a>
                        <a href="mailto:info@lixhium.com" target="_blank">
                            <div className='group px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                                <GrMail size={20} className='text-[#0C0A3E] duration-500' />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            {/* MOBILE DROPDOWN */}
            <div className={`${mobileMenu ? `h-screen pb-[80px]` : 'h-0'} absolute w-full bg-white flex flex-col md:border-none`}>
                <div className={`${mobileMenu ? 'z-50' : 'opacity-0 -z-50 hidden'} flex flex-col w-full py-5`}>
                    {/* PRODUCTS */}
                    <div onClick={() => setProduct(!product)} className='flex flex-row custom-font text-sm items-center gap-2 px-5 py-2'>
                        <div className=''>Ürünler</div>
                        <AiFillCaretDown className={`duration-500 ${product ? '-rotate-90' : ''}`} />
                    </div>
                    <div className={`${product ? 'h-24 opacity-100' : 'h-0 opacity-0'} duration-500`}>
                        {product ? <div className='px-5 pt-2'>
                            <div className='flex flex-col gap-2'>
                                <Link to={'/products/lixhium'} onClick={() => setMobileMenu(false)}>
                                    <div className='flex flex-row items-center'>
                                        <div className='group px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                                            <BsFillLightningChargeFill size={20} className='text-[#0C0A3E] duration-500 ' />
                                        </div>
                                        <div className='custom-font text-xs'>App'i Keşfet</div>
                                    </div>
                                </Link>
                                <Link to={'/products/api'} onClick={() => setMobileMenu(false)}>
                                    <div className='flex flex-row items-center'>
                                        <div className='group px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                                            <AiFillApi size={20} className='text-[#0C0A3E] duration-500 ' />
                                        </div>
                                        <div className='custom-font text-xs'>Api'i Keşfet</div>
                                    </div>
                                </Link>
                            </div>
                        </div> : null}
                    </div>
                    {/* SOLUTIONS */}
                    <div onClick={() => setSolution(!solution)} className='flex flex-row custom-font text-sm items-center gap-2 px-5 py-2'>
                        <div className=''>Çözümler</div>
                        <AiFillCaretDown className={`duration-500 ${solution ? '-rotate-90' : ''}`} />
                    </div>
                    <div className={`${solution ? 'h-36 opacity-100' : 'h-0 opacity-0'} duration-500`}>
                        {solution ? <div className='px-5 pt-2'>
                            <div className='flex flex-col gap-2'>
                                <Link to={'/solutions/cpo&emsp'} onClick={() => setDesktopMenu(false)}>
                                    <div className='flex flex-row items-center'>
                                        <div className='group px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                                            <img src={solutionArray[0].image} className='w-5 text-[#0C0A3E]'></img>
                                        </div>
                                        <div className='custom-font text-xs'>{solutionArray[0].title}</div>
                                    </div>
                                </Link>
                                <Link to={'/solutions/fleets'} onClick={() => setDesktopMenu(false)}>
                                    <div className='flex flex-row items-center'>
                                        <div className='group px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                                            <img src={solutionArray[1].image} className='w-5 text-[#0C0A3E]'></img>
                                        </div>
                                        <div className='custom-font text-xs'>{solutionArray[1].title}</div>
                                    </div>
                                </Link>
                                <Link to={'/solutions/automobiles'} onClick={() => setDesktopMenu(false)}>
                                    <div className='flex flex-row items-center'>
                                        <div className='group px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                                            <img src={solutionArray[2].image} className='w-5 text-[#0C0A3E]'></img>
                                        </div>
                                        <div className='custom-font text-xs'>{solutionArray[2].title}</div>
                                    </div>
                                </Link>
                            </div>
                        </div> : null}
                    </div>
                    {/* SERVICES */}
                    <div onClick={() => setService(!service)} className='flex flex-row custom-font text-sm items-center gap-2 px-5 py-2'>
                        <div className=''>Hizmetler</div>
                        <AiFillCaretDown className={`duration-500 ${service ? '-rotate-90' : ''}`} />
                    </div>
                    <div className={`${service ? 'h-36 opacity-100' : 'h-0 opacity-0'} duration-500`}>
                        {service ? <div className='px-5 pt-2'>
                            <div className='flex flex-col gap-2'>
                                <Link to={'/services/consult'} onClick={() => setDesktopMenu(false)}>
                                    <div className='flex flex-row items-center'>
                                        <div className='group px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                                            <img src={serviceArray[0].image} className='w-5 text-[#0C0A3E]'></img>
                                        </div>
                                        <div className='custom-font text-xs'>{serviceArray[0].title}</div>
                                    </div>
                                </Link>
                                <Link to={'/services/report'} onClick={() => setDesktopMenu(false)}>
                                    <div className='flex flex-row items-center'>
                                        <div className='group px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                                            <img src={serviceArray[1].image} className='w-5 text-[#0C0A3E]'></img>
                                        </div>
                                        <div className='custom-font text-xs'>{serviceArray[1].title}</div>
                                    </div>
                                </Link>
                                <Link to={'/services/ads'} onClick={() => setDesktopMenu(false)}>
                                    <div className='flex flex-row items-center'>
                                        <div className='group px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                                            <img src={serviceArray[2].image} className='w-5 text-[#0C0A3E]'></img>
                                        </div>
                                        <div className='custom-font text-xs'>{serviceArray[2].title}</div>
                                    </div>
                                </Link>
                            </div>
                        </div> : null}
                    </div>
                    {/* DOWNLOADS */}
                    <div onClick={() => setDownloads(!downloads)} className='flex flex-row custom-font text-sm items-center gap-2 px-5 py-2'>
                        <div className=''>İndirmeler</div>
                        <AiFillCaretDown className={`duration-500 ${downloads ? '-rotate-90' : ''}`} />
                    </div>
                    <div className={`${downloads ? 'h-24 opacity-100' : 'h-0 opacity-0'} duration-500`}>
                        {downloads ? <div className='px-5 pt-2'>
                            <div className='flex flex-col gap-2'>
                                <a href='https://play.google.com/store/apps/details?id=com.lixhium.lixhiumproapp' target='_blank'>
                                    <div className='flex flex-row items-center'>
                                        <div className='group px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                                            <FaGooglePlay size={20} className='text-[#0C0A3E] duration-500 ' />
                                        </div>
                                        <div className='custom-font text-xs'>Download Play Store</div>
                                    </div>
                                </a>
                                <a href='https://apps.apple.com/tr/app/lixhium/id1645154277' target='_blank'>
                                    <div className='flex flex-row items-center'>
                                        <div className='group px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                                            <AiFillApple size={20} className='text-[#0C0A3E] duration-500 ' />
                                        </div>
                                        <div className='custom-font text-xs'>Download App Store</div>
                                    </div>
                                </a>


                            </div>
                        </div> : null}
                    </div>
                    {/* CONTACTS */}
                    <div onClick={() => setContacts(!contacts)} className='flex flex-row custom-font text-sm items-center gap-2 px-5 py-2'>
                        <div className=''>İletişim</div>
                        <AiFillCaretDown className={`duration-500 ${contacts ? '-rotate-90' : ''}`} />
                    </div>
                    <div className={`${contacts ? 'h-36 opacity-100' : 'h-0 opacity-0'} duration-500`}>
                        {contacts ? <div className='px-5 pt-2'>
                            <div className='flex flex-col gap-2'>
                                <div onClick={handlePhoneCall} className='flex flex-row items-center'>
                                    <div className='group px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                                        <AiFillPhone size={20} className='text-[#0C0A3E] duration-500 ' />
                                    </div>
                                    <div className='custom-font text-xs'>Telefon ile arayın</div>
                                </div>
                                <div onClick={handleMailSend} className='flex flex-row items-center'>
                                    <div className='group px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                                        <GrMail size={20} className='text-[#0C0A3E] duration-500 ' />
                                    </div>
                                    <div className='custom-font text-xs'>Mail Gönderin</div>
                                </div>
                                <div onClick={handleLocationFind} className='flex flex-row items-center'>
                                    <div className='group px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                                        <MdLocationOn size={20} className='text-[#0C0A3E] duration-500 ' />
                                    </div>
                                    <div className='custom-font text-xs'>Koceeli / Gebze</div>
                                </div>
                            </div>
                        </div> : null}
                    </div>
                    <Link to={'/blogs'}>
                        <div className='flex flex-row items-center justify-start px-5 py-2  font-bold text-sm custom-font'>
                            <div>Blog</div>
                        </div>
                    </Link>
                    <div className={`${mobileMenu ? 'opacity-100' : 'opacity-0'} flex flex-row duration-500 justify-center pt-5`}>
                        <a href='https://www.instagram.com/lixhium.network/' target='_blank'>
                            <div className='group px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                                <AiFillInstagram size={20} className='text-[#0C0A3E] duration-500' />
                            </div>
                        </a>
                        <a href='https://twitter.com/lixhiumnetwork' target='_blank'>
                            <div className='group px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                                <BsTwitter size={20} className='text-[#0C0A3E] duration-500' />
                            </div>
                        </a>
                        <a href='mailto:info@lixhium.com' target='_blank'>
                            <div className='group px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                                <BsLinkedin size={20} className='text-[#0C0A3E] duration-500' />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Navbar