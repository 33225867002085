import React from 'react'

//IMPORT COMPONENTS
import Navbar from '../../components/LandingBar'
import Footer from '../../components/Footer'
import CarBanner2 from '../../media/car-banner-2.png'
import { BsArrow90DegRight, BsForwardFill, BsDot } from 'react-icons/bs'

//IMPORT BUTTON
import PrimaryButton from '../../components/buttons/PrimaryButton'

//IMPORT IMAGE
import ConsultImage1 from '../../media/consult-1.png'

//IMPORT HEADERS
import BigHeader from '../../components/headers/BigHeader'
import SmallHeader from '../../components/headers/SmallHeader'

const Consult = () => {
    const handleMailSend = () => {
        const emailAddress = 'info@lixhium.com';
        window.location.href = `mailto:${emailAddress}`;
    }
    return (
        <div className='relative'>
            <div className='absolute w-full -z-50 bg-[#F3F7F9] h-full'></div>
            <Navbar />
            <div className='p-16'></div>
            <BigHeader text={'Danışmanlık'} />
            <SmallHeader text={'İleri seviye know-how desteği.'} />
            <div onClick={() => handleMailSend()} className='py-5 md:pt-5 flex justify-center'>
                <PrimaryButton text={'Teklif Alın'} />
            </div>
            <div className='flex flex-col md:flex-row max-w-screen-lg m-auto gap-10 p-5 md:pt-5'>
                <div className='md:w-2/5 flex flex-col justify-center text-lg custom-font-light gap-2'>
                    <div className='flex flex-row gap-2 items-center'>
                        <div className='w-2 h-2 rounded-full bg-black'></div>
                        <div className='text-sm md:text-lg'>Pazar Analizi ve Fırsatlar</div>
                    </div>
                    <div className='flex flex-row gap-2 items-center'>
                        <div className='w-2 h-2 rounded-full bg-black'></div>
                        <div className='text-sm md:text-lg'>Regülasyon ve Teşvikler</div>
                    </div>
                    <div className='flex flex-row gap-2 items-center'>
                        <div className='w-2 h-2 rounded-full bg-black'></div>
                        <div className='text-sm md:text-lg'>Teknoloji ve Altyapı</div>
                    </div>
                    <div className='flex flex-row gap-2 items-center'>
                        <div className='w-2 h-2 rounded-full bg-black'></div>
                        <div className='text-sm md:text-lg'>Kurulum ve İşletme</div>
                    </div>
                    <div className='flex flex-row gap-2 items-center'>
                        <div className='w-2 h-2 rounded-full bg-black'></div>
                        <div className='text-sm md:text-lg'>Finansman ve Yatırım Modelleri</div>
                    </div>
                    <div className='flex flex-row gap-2 items-center'>
                        <div className='w-2 h-2 rounded-full bg-black'></div>
                        <div className='text-sm md:text-lg'>Pazarlama ve Müşteri İlişkileri</div>
                    </div>
                    <div className='flex flex-row gap-2 items-center'>
                        <div className='w-2 h-2 rounded-full bg-black'></div>
                        <div className='text-sm md:text-lg'>Sürdürülebilirlik ve Yeşil Enerji</div>
                    </div>
                    <div className='flex flex-row gap-2 items-center'>
                        <div className='w-2 h-2 rounded-full bg-black'></div>
                        <div className='text-sm md:text-lg'>Eğitim ve Destek</div>
                    </div>
                </div>
                <div className='md:w-3/5'>
                    <img src={ConsultImage1}></img>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Consult