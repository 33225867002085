import React from 'react';

const SmallHeader = ({ text }) => {
    const formattedText = text.replace(/\n/g, '<br>');

    return (
        <div
            className='text-2xl md:text-4xl font-bold text-center max-w-xl m-auto text-[#0C0A3E] pb-2 px-5 md:px-0 custom-font-light'
            dangerouslySetInnerHTML={{ __html: formattedText }}
        />
    );
};

export default SmallHeader;