import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick"
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

//IMPORT COMPONENTS
import LandingBar from '../components/LandingBar';
import YouTubeVideo from '../components/YoutubeVideo';

//IMPORT ICONS
import LixhiumLogo from '../media/lixhium-logo-black.svg'
import AppLogo from '../media/app-logo.svg'

//IMPORT REACT-ICONS
import { MdLocationOn } from 'react-icons/md'
import { GrMail } from 'react-icons/gr'
import { AiFillPhone, AiFillInstagram } from 'react-icons/ai'
import { BsLinkedin, BsTwitter } from 'react-icons/bs'

//IMPORT SLIDE
import AppSlide from '../components/slide/appSlide';

//IMPORT CARD
import SolutionCard from '../components/cards/SolutionCard';

//IMPORT BUTTONS
import PrimaryButton from '../components/buttons/PrimaryButton';
import MainPhone from '../media/main-phone.png'

//IMPORT HEADERS
import HeaderBig from '../components/headers/BigHeader'
import HeaderSmall from '../components/headers/SmallHeader'

import SolutionCard1 from '../media/solution-card-1.png';
import SolutionCard2 from '../media/solution-card-2.png';
import SolutionCard3 from '../media/solution-card-3.png';
import SmallHeader from '../components/headers/SmallHeader';

//IMPORT SLIDERS
import PartnerSlider from '../components/sliders/PartnerSlider';

const Landing = () => {

    const [currentSection, setCurrentSection] = useState(0)
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const sectionRef = useRef(null);

    // UPDATE SCREENHEIGHT
    useEffect(() => {
        const handleResize = () => {
            const newHeight = window.innerHeight;
            setScreenHeight(newHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleWheelScroll = (event) => {
        const delta = event.deltaY;
        if (delta > 25) {
            // Navigate to the next slide
            sectionRef.current.slickNext();
        } else if (delta < -25) {
            // Navigate to the previous slide
            sectionRef.current.slickPrev();
        }
    };

    useEffect(() => {
        document.addEventListener('wheel', handleWheelScroll);
        return () => document.removeEventListener('wheel', handleWheelScroll);
    }, []);

    const handleSectionChange = (index) => {
        setCurrentSection(index);
        console.log(currentSection)
    };

    const settings = {
        arrows: false,
        dots: false,
        infinite: false,
        vertical: true,
        verticalSwiping: true,
        swipeToSlide: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        ref: sectionRef,
    };

    const sections = [
        //LANDING SECTION
        <div key={1} style={{ height: `${screenHeight}px` }} className='h-full w-full pt-[80px] relative'>
            <div className='flex flex-col md:flex-row-reverse w-full h-full md:max-w-screen-xl m-auto gap-0'>
                <div className='w-full pt-5 md:h-full relative md:pt-0 '>
                    <img className='absolute bg-white md:bg-[#CFFF04] rounded-md p-20 w-96 h-96 left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 -z-10 opacity-0 md:opacity-5' src={LixhiumLogo} alt='lixhium-logo'></img>
                    <div className='flex flex-col h-full justify-center gap-2 relative px-5'>
                        <div className='flex flex-col items-center justify-start md:justify-center gap-2'>
                            <HeaderBig text={'Lixhium'} />
                            <SmallHeader text={'Tüm Şarj İstasyonları <br/> Tek Uygulama'} />
                            <div className='text-base md:text-xl text-center max-w-4xl m-auto text-slate-500 custom-font-light'>Keşfet. Planla. Şarj Et.</div>
                        </div>
                        <div className='flex flex-row gap-10 justify-center pb-4 pt-2 md:gap-2'>
                            <Link to='/products/api'>
                                <PrimaryButton text={"Daha Fazla Bilgi"} />
                            </Link>
                            <Link to='/products/lixhium'>
                                <PrimaryButton text={"App'i Keşfet"} />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='w-full h-auto md:h-full md:m-auto flex-grow flex justify-center'>
                    <div className={`w-40 md:w-1/2 m-auto md:p-0 `}>
                        <img className='object-cover' src={MainPhone} alt='api-banner-1'></img>
                    </div>
                </div>
            </div>
        </div>,
        //LIXHIUM APP SECTION
        <div key={2} style={{ height: `${screenHeight}px` }} className='h-full w-full pt-[80px] relative'>
            <div className='flex flex-col md:flex-row w-full h-full max-w-screen-xl m-auto gap-0'>
                <div className='w-full h-full m-auto relative'>
                    <img className='absolute md:bg-[#CFFF04] rounded-md p-20 w-96 h-96 left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 -z-10 opacity-0 md:opacity-5' src={LixhiumLogo} alt='lixhium-logo'></img>
                    <div className='flex flex-col h-full justify-center gap-2 relative p-5 z-10'>
                        <div className='flex flex-row items-center gap-2'>
                            <img className='bg-[#CFFF04] rounded-md p-2 w-8 h-8 opacity-100' src={LixhiumLogo} alt='lixhium-logo'></img>
                            <div className='text-2xl md:text-6xl font-bold text-[#0C0A3E] custom-font-bold'>Lixhium App</div>
                        </div>
                        <div className='text-xl md:text-4xl font-bold text-[#0C0A3E] custom-font'>EV Şarj Ekosistemi <br></br>Tek Platformda</div>
                        <div className='text-[#0C0A3E] custom-font-light text-sm md:text-base'>Lixhium, elektrikli araç şarj ekosistemini tek bir platformda toplayan, lisanslı şarj istasyonu işletmenleri ile elektrikli araç kullanıcılarını bir araya getiren, tüm istasyonların aynı haritada gösterilmesi ile birlikte; navigasyon, akıllı yol planlama, şarj başlatma, check-in / yorum yapma, ödeme, dijital cüzdan, puan toplama, sadakat programları, tarife karşılaştırma, araca uygun şarj miktarı ve ücreti hesaplama ve mobil şarj hizmeti gibi pek çok işe yarar özellikleri bulunan 'tarafsız' bir pazar yeridir.</div>
                        <div className='flex flex-col md:flex-row justify-between md:justify-normal pt-4 gap-2 relative'>
                            <a href="https://play.google.com/store/apps/details?id=com.lixhium.lixhiumproapp" target="_blank">
                                <div className='flex'>
                                    <PrimaryButton text={"PlayStore'dan indir"} />
                                </div>
                            </a>

                            <a href="https://apps.apple.com/tr/app/lixhium/id1645154277" target="_blank">
                                <div className='flex'>
                                    <PrimaryButton text={"App Store'dan indir"} />
                                </div>
                            </a>

                            <div className='absolute md:hidden w-full h-full flex justify-end items-center -z-10'>
                                <img src={AppLogo} className='w-16'></img>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='hidden md:flex w-full h-full m-auto justify-center'>
                    <AppSlide className='' mirror={false} />
                </div>
            </div>
        </div>,
        //SOLUTIONS SECTION
        <div key={3} style={{ height: `${screenHeight}px` }} className='h-full w-full pt-[80px] relative'>
            <div className='flex flex-col w-full h-full max-w-screen-xl m-auto gap-0 items-center justify-center'>
                <div className='custom-font text-2xl md:text-4xl w-full text-center py-2'>Çözümler</div>
                <div className='custom-font-light text-sm md:text-xl text-center max-w-2xl px-5'>İşinize uygun şekilde özelleştirilebilir.<br></br>Lixhium’un şirketiniz için neler yapabileceğine göz atın.</div>
                <div className='flex flex-col px-5 md:flex-row pt-10 gap-2 md:gap-10'>
                    <Link to={'/solutions/cpo&emsp'}>
                        <SolutionCard text={'CPO & eMSP'} img={SolutionCard3} />
                    </Link>
                    <Link to={'/solutions/fleets'}>
                        <SolutionCard text={'Filolar'} img={SolutionCard2} />
                    </Link>
                    <Link to={'/solutions/automobiles'} >
                        <SolutionCard text={'Otomotiv'} img={SolutionCard1} />
                    </Link>
                </div>
            </div>
        </div>,
        //MEDIA SECTION
        <div key={4} style={{ height: `${screenHeight}px` }} className='h-full w-full pt-[80px] relative'>
            <div className='flex flex-col w-full h-full max-w-screen-xl m-auto gap-0 items-center justify-center mt-10'>
                <div className='custom-font text-2xl md:text-4xl w-full text-center py-2'>Medya</div>
                <div className='custom-font-light text-sm md:text-xl text-center max-w-2xl px-5'>Basında Lixhium</div>
                <div className='flex flex-col px-5 md:flex-row pt-10 gap-2 md:gap-10'>
                    <YouTubeVideo videoUrl={'https://www.youtube.com/watch?v=EY7ddAO-8bY'} />
                    <YouTubeVideo videoUrl={'https://www.youtube.com/watch?v=wslA8oZuV6c'} />
                </div>
                <PartnerSlider />
            </div>
        </div>,
        //CONTACT SECTION
        <div key={5} style={{ height: `${screenHeight}px` }} className='h-full w-full pt-[80px] relative'>
            <div className='flex flex-col md:flex-row w-full h-full max-w-screen-xl m-auto gap-0'>
                <div className='w-full h-1/2 md:h-full md:m-auto relative'>
                    <img className='absolute md:bg-[#CFFF04] rounded-md p-20 w-96 h-96 left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 -z-10 opacity-0 md:opacity-5' src={LixhiumLogo} alt='lixhium-logo'></img>
                    <div className='flex flex-col h-full justify-center gap-2 relative px-5'>
                        <div className='flex flex-row items-center gap-2'>
                            <img className='bg-[#CFFF04] rounded-md p-2 w-8 h-8 opacity-100' src={LixhiumLogo} alt='lixhium-logo'></img>
                            <div className='text-2xl md:text-6xl font-bold text-[#0C0A3E]'>İletişim</div>
                        </div>
                        <div className='text-xl md:text-4xl font-bold text-[#0C0A3E]'>Lixhium</div>
                        <div className='text-[#0C0A3E]'>Bizimle iletişime geçin</div>
                        <div className='flex flex-row gap-2 md:gap-5'>
                            <a href="tel:+90 850 749 99 44" target="_blank">
                                <div className='flex'>
                                    <div className='group px-4 py-2 bg-[#CFFF04] md:hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                                        <AiFillPhone size={25} className='text-[#0C0A3E] duration-500' />
                                    </div>
                                </div>
                            </a>
                            <a href="mailto:info@lixhium.com" target="_blank">
                                <div className='flex'>
                                    <div className='group px-4 py-2 bg-[#CFFF04] md:hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                                        <GrMail size={25} className='text-[#0C0A3E] duration-500' />
                                    </div>
                                </div>
                            </a>
                            <a href="https://www.google.com/maps?ll=40.790344,29.511099&z=16&t=m&hl=tr&gl=TR&mapclient=embed&cid=4881880894948552485" target="_blank">
                                <div className='flex'>
                                    <div className='group px-4 py-2 bg-[#CFFF04] md:hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer'>
                                        <MdLocationOn size={25} className='text-[#0C0A3E] duration-500' />
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='w-full h-1/2 md:h-full m-auto flex flex-col justify-center gap-5 px-5'>
                    <a href="https://www.instagram.com/lixhium.network/" target="_blank">
                        <div className='flex'>
                            <div className='flex flex-row gap-5 px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer items-center'>
                                <AiFillInstagram size={25} className='text-[#0C0A3E] duration-500' />
                                <div className='text-xs md:text-sm custom-font'>Instagram</div>
                            </div>
                        </div>
                    </a>
                    <a href="https://twitter.com/lixhiumnetwork" target="_blank">
                        <div className='flex'>
                            <div className='flex flex-row gap-5 px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer items-center'>
                                <BsTwitter size={25} className='text-[#0C0A3E] duration-500' />
                                <div className='text-xs md:text-sm custom-font'>Twitter</div>
                            </div>
                        </div>
                    </a>
                    <a href="https://www.linkedin.com/company/lixhium/" target="_blank">
                        <div className='flex'>
                            <div className='flex flex-row gap-5 px-4 py-2 hover:bg-[#CFFF04] duration-500 rounded-md cursor-pointer items-center'>
                                <BsLinkedin size={25} className='text-[#0C0A3E] duration-500' />
                                <div className='text-xs md:text-sm custom-font'>Linkedin</div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>,
    ]

    return (
        <div style={{ height: `${screenHeight}px` }} className='h-full w-full overflow-hidden bg-[#F3F7F9]'>
            <LandingBar currentSection={currentSection} screenHeight={screenHeight} />
            <Slider sectionRef={sectionRef} {...settings} beforeChange={(_, newIndex) => handleSectionChange(newIndex)} >
                {sections.map((div, index) => (
                    <div
                        key={index}
                        className="w-full relative overflow-hidden"
                        style={{ height: `${screenHeight}px` }}
                    >
                        {div}
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default Landing