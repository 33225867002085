import React from 'react'
import { useParams } from "react-router-dom"

import { BsFillPersonFill, BsDot } from 'react-icons/bs'
import { CiLinkedin } from "react-icons/ci";

// IMPORT DATA 
import { blogs2 } from '../data/blog'

// IMPORT COMPONENT
import Navbar from '../components/LandingBar'
import Footer from '../components/Footer'

const Blog = () => {

    const { param } = useParams()
    const blog = blogs2.find(obj => obj.id === parseInt(param));

    return (
        <div className='overflow-x-hidden relative'>
            <div className='absolute w-full -z-50 bg-[#F3F7F9] h-full'></div>
            <Navbar />
            <div className='p-12 md:p-16'></div>
            <div className='text-lg md:text-4xl font-bold md:text-center max-w-4xl m-auto p-5 text-[#0C0A3E]'>{blog.text}</div>
            <div className='flex flex-col md:flex-row md:items-center gap-5 p-5 md:p-10 md:justify-center'>
                <BsFillPersonFill size={25} />
                <div className='font-bold'>Berke Astarcıoğlu</div>
                {/* <div className='text-slate-500'>{blog.date}</div> */}
                <BsDot size={15} />
                <div className='text-slate-500'>4dk okuma</div>
                <a href={blog.link}>
                    <div className='flex flex-col md:flex-row custom-font-light text-sky-400 gap-2 md:items-center'>
                        <CiLinkedin size={25} />
                        <div>LinkedIn Gönderisini Görüntüleyin</div>
                    </div>
                </a>
            </div>
            {/* <img className='max-w-screen-lg m-auto w-full h-[500px] object-cover' src={blog.img[0]} alt=''></img> */}
            <div
                dangerouslySetInnerHTML={{ __html: blog.div }}
                className='max-w-screen-xl m-auto text-xl p-5 xl:px-0 md:py-10 custom-font-light'
            ></div>
            <div className='max-w-screen-xl m-auto w-full grid grid-cols-2 gap-5 '>
                {blog.img.map((img, index) => (
                    <img
                        key={index}
                        className='object-cover'
                        src={img}
                        alt={`Blog Image ${index + 1}`}
                    />
                ))}
            </div>

            <Footer />
        </div>
    )
}

export default Blog