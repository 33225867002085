import React from 'react'

//IMPORT COMPONENTS
import Navbar from '../../components/LandingBar'
import Footer from '../../components/Footer'

//IMPORT BUTTONS
import PrimaryButton from '../../components/buttons/PrimaryButton'

//IMPORT IMAGE
import ReportImage1 from '../../media/report-1.png'
import ReportImage2 from '../../media/report-2.png'
import ReportImage3 from '../../media/report-3.png'
import ReportImage4 from '../../media/report-4.png'
import ReportImage5 from '../../media/report-5.png'
import ReportImage6 from '../../media/report-6.png'
import ReportImage7 from '../../media/report-7.png'

//IMPORT HEADERS
import BigHeader from '../../components/headers/BigHeader'
import SmallHeader from '../../components/headers/SmallHeader'

const Report = () => {
    const handleMailSend = () => {
        const emailAddress = 'info@lixhium.com';
        window.location.href = `mailto:${emailAddress}`;
    }
    return (
        <div className='relative'>
            <div className='absolute w-full h-full -z-50 bg-[#F3F7F9]'></div>
            <Navbar />
            <div className='p-16'></div>
            <BigHeader text={'Raporlama'} />
            <SmallHeader text={'Detaylarla sektöre hakim olun.'} />
            <div onClick={() => handleMailSend()} className='py-5 md:pt-5 flex justify-center'>
                <PrimaryButton text={'Teklif Alın'} />
            </div>
            <div className='flex flex-col-reverse md:flex-row max-w-screen-xl m-auto gap-10 p-5 md:pt-5'>
                <div className='md:w-3/5 flex flex-col md:flex-row gap-5 items-center'>
                    <div className='md:w-1/2'>
                        <img className='object-cover' src={ReportImage1}></img>
                    </div>
                    <div className='md:w-1/2'>
                        <img className='object-cover' src={ReportImage2}></img>
                    </div>
                </div>
                <div className='md:w-2/5 flex flex-col justify-center text-lg custom-font-light gap-2'>
                    <div className='flex flex-row gap-2 items-center'>
                        <div className='w-2 h-2 rounded-full bg-black shrink-0'></div>
                        <div className='text-sm md:text-lg'>Türkiye’nin güncel şarj istasyonu haritası</div>
                    </div>
                    <div className='flex flex-row gap-2 items-center'>
                        <div className='w-2 h-2 rounded-full bg-black shrink-0'></div>
                        <div className='text-sm md:text-lg'>Kurulum ve altyapı elverişliliği tespiti</div>
                    </div>
                    <div className='flex flex-row gap-2 items-center'>
                        <div className='w-2 h-2 rounded-full bg-black shrink-0'></div>
                        <div className='text-sm md:text-lg'>Şarj istasyonu ısı haritası</div>
                    </div>
                    <div className='flex flex-row gap-2 items-center'>
                        <div className='w-2 h-2 rounded-full bg-black shrink-0'></div>
                        <div className='text-sm md:text-lg'>Elektrikli araç kullanıcısı davranış analizi</div>
                    </div>
                    <div className='flex flex-row gap-2 items-center'>
                        <div className='w-2 h-2 rounded-full bg-black shrink-0'></div>
                        <div className='text-sm md:text-lg'>En çok rota oluşturulan yollar</div>
                    </div>
                    <div className='flex flex-row gap-2 items-center'>
                        <div className='w-2 h-2 rounded-full bg-black shrink-0'></div>
                        <div className='text-sm md:text-lg'>Elektrikli araçların gerçek veritabanı ve şarjlanma analizleri</div>
                    </div>
                    <div className='flex flex-row gap-2 items-center'>
                        <div className='w-2 h-2 rounded-full bg-black shrink-0'></div>
                        <div className='text-sm md:text-lg'>Lokasyon bazlı rekabet analizi</div>
                    </div>
                </div>
            </div>

            <div className='text-2xl md:text-4xl font-bold text-center max-w-4xl m-auto text-[#0C0A3E] pb-2 p-5 custom-font-light'>Türkiye'nin en geniş veritabanı</div>
            <div className='max-w-screen-xl m-auto px-5 pt-10 flex flex-col md:flex-row gap-5'>
                <div className='md:w-1/2'>
                    <img src={ReportImage3} alt='report'></img>
                </div>
                <div className='md:w-1/2'>
                    <img src={ReportImage4} alt='report'></img>
                </div>
            </div>
            <div className='max-w-screen-xl m-auto px-5 pt-10 flex flex-col md:flex-row gap-5'>
                <div className='md:w-1/2'>
                    <img src={ReportImage5} alt='report'></img>
                </div>
                <div className='md:w-1/2'>
                    <img src={ReportImage6} alt='report'></img>
                </div>
            </div>
            <div className='max-w-screen-xl m-auto px-5 pt-10 flex flex-col md:flex-row gap-5 justify-center'>
                <div className='md:w-1/2'>
                    <img src={ReportImage7} alt='report'></img>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Report