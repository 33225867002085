import React from 'react'
import { useNavigate } from 'react-router-dom';

import { BsFillPersonFill, BsDot } from 'react-icons/bs'

// IMPORT COMPONENT
import Navbar from '../components/LandingBar'
import Footer from '../components/Footer'

// IMPORT DATA 
import { blogs, blogs2 } from '../data/blog'

import LinkedBlogCard from '../components/cards/LinkedBlogCard';

const Blogs = () => {
    const navigate = useNavigate();

    // HANDLE CLICKS
    const handleNavigation = (path) => {
        window.scrollTo(0, 0)
        navigate(path)
    }
    return (
        <div className='overflow-x-hidden relative'>
            <div className='absolute w-full -z-50 bg-[#F3F7F9] h-full'></div>
            <Navbar />
            <div className='p-12 md:p-16'></div>
            <div onClick={() => handleNavigation(`/blogs/${blogs[0].id}`)} className='max-w-6xl flex flex-col m-auto sm:rounded-xl shadow-xl hover:cursor-pointer'>
                <img className='h-96 object-cover sm:rounded-xl' src={blogs[0].img} alt=''></img>
                <div className='p-5'>
                    <div className='text-lg md:text-2xl font-bold text-start p-5 text-[#0C0A3E]'>{blogs[0].title}</div>
                    <div className='text-md md:text-lg text-slate-500 px-5'>{blogs[0].text.slice(0, 186) + '...'}</div>
                    <div className='flex flex-row p-5 gap-2 text-slate-500 items-center'>
                        <BsFillPersonFill size={20} />
                        <div className=''>{blogs[0].author}</div>
                        <BsDot size={20} />
                        <div>{blogs[0].time} okuma</div>
                    </div>
                </div>
            </div>
            <div className='grid lg:grid-cols-2 max-w-6xl m-auto pt-10 gap-5'>
                {blogs.map((item, index) => {
                    if (index === 0) {
                        return null;
                    }
                    return (
                        <div onClick={() => handleNavigation(`/blogs/${item.id}`)} key={item.id} className='shadow-xl sm:rounded-xl cursor-pointer'>
                            <img className='h-96 object-cover sm:rounded-xl m-auto' src={item.img} alt=''></img>
                            <div className='p-5'>
                                <div className='text-lg md:text-2xl font-bold text-start p-5 text-[#0C0A3E]'>{item.title}</div>
                                <div className='text-md md:text-lg text-slate-500 px-5'>{item.text.slice(0, 186) + '...'}</div>
                                <div className='flex flex-row p-5 gap-2 text-slate-500 items-center'>
                                    <BsFillPersonFill size={20} />
                                    <div className=''>{item.author}</div>
                                    <BsDot size={20} />
                                    <div>{item.time} okuma</div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className='grid lg:grid-cols-2 max-w-6xl m-auto pt-10 gap-5'>
                {blogs2.map((item, index) => {
                    return (
                        <div onClick={() => handleNavigation(`/blogs2/${item.id}`)} key={item.id} className='shadow-xl sm:rounded-xl cursor-pointer'>
                            <LinkedBlogCard item={item} />
                        </div>
                    )
                })}
            </div>
            <Footer />
        </div>
    )
}

export default Blogs