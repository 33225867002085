import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom';
import { BsTwitter, BsLinkedin } from 'react-icons/bs'
import { AiFillInstagram } from 'react-icons/ai'

// IMPORT LOGO
import LixhiumLogo from '../media/lixhium-logo-black.svg'
import AppleLogo from '../media/apple-logo-black.svg'
import PlayLogo from '../media/play-logo-black.svg'

const Footer = () => {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    };

    const handleAppDownload = () => {
        window.open('https://apps.apple.com/tr/app/lixhium/id1645154277', '_blank');
    }

    const handlePlayDownload = () => {
        window.open('https://play.google.com/store/apps/details?id=com.lixhium.lixhiumproapp', '_blank');
    }

    // HANDLE HREF
    const handleHref = (path) => {
        window.open(path, "_blank");
    }

    return (
        <div className='flex flex-col lg:flex-row max-w-screen-2xl w-full m-auto p-2 sm:p-5 gap-5 md:gap-10 bg-[#F3F7F9] mt-10'>
            <div className='w-full lg:w-1/2 flex flex-col shadow-md p-2 rounded-2xl border-2 border-[#CFFF04] bg-white '>
                {/* LOGO */}
                <div onClick={() => handleNavigation('/')} className='flex flex-row items-center p-5 gap-2 md:gap-5 border-b-2 cursor-pointer'>
                    <img className='w-6 h-6 md:h-10 md:w-10' src={LixhiumLogo} alt='lixhium-logo'></img>
                    <div className='text-lg md:text-2xl font-bold text-[#0C0A3E] custom-font'> Lixhium </div>
                </div>

                <div className='flex flex-col md:flex-row p-5 gap-5 duration-500 text-sm md:text-base'>
                    <div className='flex flex-col' >
                        <div className='text-lg custom-font'>Ürünler</div>
                        <div className='flex flex-col custom-font-light duration-500'>
                            <div onClick={() => handleNavigation('/products/lixhium')} className='cursor-pointer md:hover:text-slate-500'>Lixhium</div>
                            <div onClick={() => handleNavigation('/products/api')} className='cursor-pointer md:hover:text-slate-500'>Api</div>
                        </div>
                    </div>

                    <div className='flex flex-col'>
                        <div className='text-lg custom-font'>Çözümler</div>
                        <div className='flex flex-col custom-font-light duration-500'>
                            <div onClick={() => handleNavigation('/solutions/cpo&emsp')} className='cursor-pointer md:hover:text-slate-500'>CPO & eMSP</div>
                            <div onClick={() => handleNavigation('/solutions/fleets')} className='cursor-pointer md:hover:text-slate-500'>Filolar</div>
                            <div onClick={() => handleNavigation('/solutions/automobiles')} className='cursor-pointer md:hover:text-slate-500'>Otomotiv</div>
                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <div className='text-lg custom-font'>Hizmetler</div>
                        <div className='flex flex-col custom-font-light duration-500'>
                            <div onClick={() => handleNavigation('/services/consult')} className='cursor-pointer md:hover:text-slate-500'>Danışmanlık</div>
                            <div onClick={() => handleNavigation('/services/report')} className='cursor-pointer md:hover:text-slate-500'>Raporlama</div>
                            <div onClick={() => handleNavigation('/services/ads')} className='cursor-pointer md:hover:text-slate-500'>Reklam ve Hizmetler</div>
                        </div>
                    </div>
                    <div onClick={() => handleNavigation('/blogs')} className='text-lg custom-font'>Blog</div>
                </div>
                <div className='flex flex-row gap-5 p-5'>
                    <AiFillInstagram onClick={() => handleHref('https://www.instagram.com/lixhium.network/')} className='cursor-pointer text-[20px]' />
                    <BsTwitter onClick={() => handleHref('https://twitter.com/lixhiumnetwork')} className='cursor-pointer text-[20px]' />
                    <BsLinkedin onClick={() => handleHref('https://www.linkedin.com/company/lixhium/')} className='cursor-pointer text-[20px]' />
                </div>
            </div>
            <div className='w-full lg:w-1/2 flex flex-col shadow-md px-5 py-10 rounded-2xl justify-center gap-5 border-2 border-[#CFFF04] bg-white custom-font-light'>
                <div className='text-center text-slate-500 text-base md:text-xl max-w-lg self-center'>EV ekosisteminin sunduğu kolaylıkları yaşamaya başlamak için Lixhium mobil uygulamasını şimdi indirin!</div>
                <div className='flex flex-row justify-center gap-10'>
                    <div onClick={handleAppDownload} className='flex flex-row gap-2 items-center cursor-pointer hover:text-black/50 duration-500 text-sm md:text-base'>
                        <img className='h-6 w-6' src={AppleLogo} alt='app-logo'></img>
                        <div>Apple Store</div>
                    </div>
                    <div onClick={handlePlayDownload} className='flex flex-row gap-2 items-center cursor-pointer hover:text-black/50 duration-500 text-sm md:text-base'>
                        <img className='h-5 w-5' src={PlayLogo} alt='play-logo'></img>
                        <div>Play Store</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer