import React from 'react'
import { useNavigate } from 'react-router-dom';

// IMPORT COMPONENTS
import Navbar from '../../components/LandingBar'
import Footer from '../../components/Footer'

// IMPORT BANNER
import TruckBanner1 from '../../media/truck-banner-1.png'
import TruckBanner2 from '../../media/truck-banner-3.png'
import FleetsBanner1 from '../../media/fleets-banner-1.png'

// IMPORT LOGO
import GraphLogo2 from '../../media/graph-logo-2-black.svg'
import TruckLogo2 from '../../media/truck-logo-2-black.svg'
import RoadLogo from '../../media/road-logo-black.svg'
import PrimaryButton from '../../components/buttons/PrimaryButton';
import FourthButton from '../../components/buttons/FourthButton';

import { BsFillLightningChargeFill } from 'react-icons/bs'

// IMPORT HEADERS
import HeaderBig from '../../components/headers/BigHeader'
import HeaderSmall from '../../components/headers/SmallHeader'

const Fleets = () => {
    const navigate = useNavigate();
    // HANDLE NAVIGATION
    const handleNavigation = (path) => {
        window.scrollTo(0, 0)
        navigate(path)
    }
    const handleMailSend = () => {
        const emailAddress = 'info@lixhium.com';
        window.location.href = `mailto:${emailAddress}`;
    }
    return (
        <div className='overflow-x-hidden relative'>
            <div className='absolute w-full h-full -z-50 bg-[#F3F7F9]'></div>
            <Navbar />
            <div className='p-16'></div>
            <HeaderBig text={'Filolar'} />
            <HeaderSmall text={"Filonuzun şarj operasyonlarını yönetin."} />
            <div className='flex justify-center pt-5'>
                <div onClick={() => handleMailSend()} className='inline-block'>
                    <PrimaryButton text={'İletişime Geçin'} />
                </div>
            </div>
            <div className='flex justify-center pt-10'>
                <img className='max-w-sm sm:max-w-lg md:max-w-4xl' src={TruckBanner2} alt='truck-banner'></img>
            </div>

            <div className='flex flex-col lg:flex-row gap-5 justify-between pt-10 max-w-screen-xl m-auto p-5'>
                <div className='flex flex-row lg:flex-col p-5 md:px-10 gap-5 shadow-md rounded-xl w-full lg:max-w-sm justify-center text-center border-2 border-[#F3F7F9] bg-white'>
                    <img className='h-12 w-12 self-center' src={GraphLogo2} alt='truck-logo-2'></img>
                    <div className='text-sm md:text-lg text-slate-500 custom-font-light text-start'>Filonuza özel fiyatlar ile farklı markalardaki şarj maliyetlerinizi sabitleyin.</div>
                </div>
                <div className='m-auto'>
                    <BsFillLightningChargeFill className='text-[#003B95] text-sm md:text-xl' />
                </div>
                <div className='flex flex-row lg:flex-col p-5 md:px-10 gap-5 shadow-md rounded-xl w-full lg:max-w-sm justify-center text-center border-2 border-[#F3F7F9] bg-white'>
                    <img className='h-12 w-12 self-center' src={TruckLogo2} alt='truck-logo-2'></img>
                    <div className='text-sm md:text-lg text-slate-500 custom-font-light text-start'>Araçlarınızın enerji tüketimlerini ve şarj faturalarını tek bir yerden takip edin.</div>
                </div>
                <div className='m-auto'>
                    <BsFillLightningChargeFill className='text-[#003B95] text-sm md:text-xl' />
                </div>
                <div className='flex flex-row lg:flex-col p-5 md:px-10 gap-5 shadow-md rounded-xl w-full lg:max-w-sm justify-center text-center border-2 border-[#F3F7F9] bg-white'>
                    <img className='h-12 w-12 self-center' src={RoadLogo} alt='road-logo'></img>
                    <div className='text-sm md:text-lg text-slate-500 custom-font-light text-start'>Filonuzdaki araçların güzergahları için daha verimli rota planlaması yapın.</div>
                </div>
            </div>

            <div className='flex flex-col lg:flex-row max-w-screen-2xl m-auto lg:pt-20 justify-center'>
                <div className='flex flex-col w-full lg:w-1/2 p-5 lg:p-10 gap-5 max-w-xl'>
                    <div className='text-2xl md:text-3xl font-bold text-[#0C0A3E] custom-font'>Maliyet Etkin Filo Elektrifikasyonu</div>
                    <div className='text-base md:text-lg text-slate-500 custom-font-light'>Mevcut filo çözümleri genellikle ekosistemin şarj tedariki (şarj altyapısına erişim ve batarya kapasitesi) tarafına odaklanırken, şarj talebi (filo operasyonunuzu gün boyu ve yıl boyu çalıştırmak için ihtiyaç duyulan enerji miktarı) üzerine daha az odaklanmaktadır. Bununla birlikte, herhangi bir filoyu ölçeklendirmek için şarj talebinizi şarj tedarikinizle doğru şekilde eşleştirmeniz gerekmektedir.</div>
                    <div className='text-base md:text-lg text-slate-500 custom-font-light'>Lixhium, dünya genelinde herhangi bir EV markası veya modeli için herhangi bir rotadaki şarj talebini (gereken enerji tüketimi) hesaplayabilir ve yol boyunca optimize edilmiş şarj durakları belirleyebilir. Bu, araçlarınızın hedeflenen SoC (Anlık Şarj Durumu) ayarını doğru bir şekilde belirlemenizi sağlar ve şarj tedarik altyapınızı en iyi şekilde kullanmanızı garanti eder. Böylelikle filolarınız için yıllık / aylık alım hesaplaması yapabilir, maliyetlerinizi düşürebilirsiniz.</div>
                    <div onClick={() => handleNavigation('/products/api')} className='flex'>
                        <FourthButton text={"API'ımızı Keşfedin"} />
                    </div>
                </div>
                <div className='w-full lg:w-1/2 pt-20 justify-center flex'>
                    <img className='max-w-xs lg:max-w-lg xl:max-w-2xl object-contain' src={FleetsBanner1} alt='car-banner-2'></img>
                </div>
            </div>
            <div className='text-2xl md:text-3xl font-bold p-5 pt-20 lg:text-center text-[#0C0A3E] custom-font'>Maliyet Tasarrufu Fırsatı</div>
            <div className='text-base md:text-lg text-slate-500 lg:text-center max-w-2xl lg:m-auto px-5 custom-font-light'>Orkestra edilmiş şarj ile, filolar elektrikli yakıt maliyetlerinin %40'ına kadar tasarruf edebilir. Lixhium, yakıt ve operasyonel maliyetleri önemli ölçüde azaltmak için dahili tahmin modelleri kullanır. Menzil tahminlerini ve EV'lerin bataryasını etkileyen sayısız değişkeni yönlendirmek için EV'ye özgü rotalama kullanarak, filo operatörleri EV'lerin sağladığı büyük maliyet tasarrufu fırsatlarından faydalanabilir.</div>
            <div className='flex justify-center pt-5'>
                <div onClick={() => handleMailSend()} className='inline-block'>
                    <PrimaryButton text={'İletişime Geçin'} />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Fleets