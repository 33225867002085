import React from 'react'

const ThirdButton = ({ image, title, text }) => {
    return (
        <div className='flex flex-col w-full gap-5 py-5 px-4 bg-[#CFFF04] text-[#0C0A3E] md:bg-transparent md:hover:bg-[#CFFF04] duration-500 cursor-pointer items-center text-xs md:text-sm'>
            <img src={image} className='h-12 w-12' alt='lixhium-logo'></img>
            <div className='flex flex-col'>
                <div className='font-bold custom-font'>{title}</div>
                <div className='text-black/50 text-center'>{text}</div>
            </div>
        </div>
    )
}

export default ThirdButton