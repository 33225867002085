import React from 'react'
import { useNavigate } from 'react-router-dom';

// IMPORT COMPONENTS
import Navbar from '../../components/LandingBar'
import Footer from '../../components/Footer'

// IMPORT LOGO
import { BsFillLightningChargeFill } from 'react-icons/bs'

//IMPORT IMAGES
import EarthBanner1 from '../../media/earth-banner-1.png'

//IMPORT BUTTONS
import PrimaryButton from '../../components/buttons/PrimaryButton'

//IMPORT HEADERS
import HeaderBig from '../../components/headers/BigHeader'
import HeaderSmall from '../../components/headers/SmallHeader'

const Api = () => {
    const navigate = useNavigate();
    const handleMailSend = () => {
        const emailAddress = 'info@lixhium.com';
        window.location.href = `mailto:${emailAddress}`;
    }

    // HANDLE NAVIGATION
    const handleNavigation = (path) => {
        window.scrollTo(0, 0)
        navigate(path)
    }
    return (
        <div className='overflow-x-hidden relative'>
            <div className='w-full h-full absolute -z-50 bg-[#F3F7F9]'></div>
            <Navbar />
            <div className='p-16'></div>
            <HeaderBig text={'Lixhium API'} />
            <HeaderSmall text={"İhtiyacınız olan API'i seçin ve bağlanın."} />
            <div className='text-base md:text-xl text-center max-w-4xl m-auto text-slate-500 custom-font-light'>Daha güçlü. Daha akıllı. Daha verimli.</div>
            <div className='flex justify-center py-5 md:py-10'>
                <div onClick={() => handleMailSend()}>
                    <PrimaryButton onClick={() => handleMailSend} text={'İletişime Geçin ve Teklif Alın'} />
                </div>
            </div>
            <div className='flex justify-center'>
                <img id='rotate-image' className='max-w-[300px] md:max-w-2xl absolute -z-20' src={EarthBanner1} alt='earth-banner-1'></img>
            </div>
            <div className='flex flex-col md:flex-row justify-between px-5 gap-5 md:gap-20 max-w-screen-xl m-auto pt-2'>
                <div className='inline-block shadow-md relative rounded-xl border-2 border-[#F3F7F9] md:hover:scale-105 duration-500'>
                    <div className='absolute w-full h-full bg-white opacity-90 sm:opacity-85 -z-10 rounded-lg'></div>
                    <div className='p-5 md:p-10 max-w-xl'>
                        <div className='font-bold text-xl text-[#0C0A3E] custom-font'>En detaylı EV rota planlama motoru</div>
                        <div className='text-sm md:text-base text-slate-500 pt-2'>Rota planlama motorumuzu elektrikli mobilite ekosistemine yönelik olarak tasarladık. Kendi geliştirdiğimiz veritabanı teknolojisi ile EV'ler için özel olarak optimize edilmiş yapay zeka destekli çoklu dinamik değişkenlere yönelik planlama algoritmasını kullanıyoruz.</div>
                    </div>
                </div>
                <div className='inline-block shadow-md relative rounded-xl border-2 border-[#F3F7F9] md:hover:scale-105 duration-500'>
                    <div className='absolute w-full h-full bg-white opacity-90 sm:opacity-85 -z-10 rounded-lg'></div>
                    <div className='p-5 md:p-10 max-w-xl'>
                        <div className='font-bold text-xl text-[#0C0A3E] custom-font'>Elektrikli araç veritabanı</div>
                        <div className='text-sm md:text-base text-slate-500 pt-2'>Araç modülümüz farklı koşullar altında EV tüketimini yönetir. Her EV, gerçek dünya doğrulamasına dayanan kendi tüketim modeline sahiptir.</div>
                        <div className='flex flex-col pt-5'>
                            <div className='flex flex-row items-center gap-2'>
                                {/* Md'den küçük olduğunda size küçülsün */}
                                <BsFillLightningChargeFill className='text-[#003B95] text-sm md:text-xl' />
                                <div className='text-sm md:text-base text-[#0C0A3E]/50'>Tüketim modelleri</div>
                            </div>
                            <div className='flex flex-row items-center gap-2'>
                                <BsFillLightningChargeFill className='text-[#003B95] text-sm md:text-xl' />
                                <div className='text-sm md:text-base text-[#0C0A3E]/50'>Filolar ve özel sahiplik</div>
                            </div>
                            <div className='flex flex-row items-center gap-2'>
                                <BsFillLightningChargeFill className='text-[#003B95] text-sm md:text-xl' />
                                <div className='text-sm md:text-base text-[#0C0A3E]/50'>Yeni ve güncel modeller</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col md:flex-row justify-between px-5 gap-5 md:gap-20 pt-5 md:pt-20 max-w-screen-xl m-auto'>
                <div className='inline-block shadow-md relative rounded-xl border-2 border-[#F3F7F9] md:hover:scale-105 duration-500'>
                    <div className='absolute w-full h-full bg-white opacity-90 sm:opacity-85 -z-10 rounded-lg'></div>
                    <div className='p-5 md:p-10 max-w-xl'>
                        <div className='font-bold text-xl text-[#0C0A3E] custom-font'>Mobil uygulama entegrasyonu</div>
                        <div className='text-sm md:text-base text-slate-500 pt-2'>Lixhium özelliklerini, mobil uygulamanız üzerinden kullanıcılarınıza sunmak için size özel API desteği</div>
                        <div className='flex flex-col pt-5'>
                            <div className='flex flex-row items-center gap-2'>
                                <BsFillLightningChargeFill className='text-[#003B95] text-sm md:text-xl' />
                                <div className='text-sm md:text-base text-[#0C0A3E]/50'>Kişiselleştirilebilir Görünüm</div>
                            </div>
                            <div className='flex flex-row items-center gap-2'>
                                <BsFillLightningChargeFill className='text-[#003B95] text-sm md:text-xl' />
                                <div className='text-sm md:text-base text-[#0C0A3E]/50'>Canlı veri akışı</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='inline-block shadow-md relative rounded-xl border-2 border-[#F3F7F9] md:hover:scale-105 duration-500'>
                    <div className='absolute w-full h-full bg-white opacity-90 sm:opacity-85 -z-10 rounded-lg'></div>
                    <div className='p-5 md:p-10 max-w-xl'>
                        <div className='font-bold text-xl text-[#0C0A3E] custom-font'>Şarj istasyonu veri tabanı</div>
                        <div className='text-sm md:text-base text-slate-500 pt-2'>İstasyon modülümüz, ihtiyaçlarınıza uygun ve müsait bir istasyona yönelmenizi sağlar.</div>
                        <div className='flex flex-col pt-5'>
                            <div className='flex flex-row items-center gap-2'>
                                <BsFillLightningChargeFill className='text-[#003B95] text-sm md:text-xl' />
                                <div className='text-sm md:text-base text-[#0C0A3E]/50'>Müsaitlik, güç, işletmeci</div>
                            </div>
                            <div className='flex flex-row items-center gap-2'>
                                <BsFillLightningChargeFill className='text-[#003B95] text-sm md:text-xl' />
                                <div className='text-sm md:text-base text-[#0C0A3E]/50'>İmkanlar</div>
                            </div>
                            <div className='flex flex-row items-center gap-2'>
                                <BsFillLightningChargeFill className='text-[#003B95] text-sm md:text-xl' />
                                <div className='text-sm md:text-base text-[#0C0A3E]/50'>OCPI uyumu ile rezervasyon ve şarj başlatma</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Api