import React, { useRef, useState } from 'react';
import AppCard from '../../components/cards/AppCard';

const AppGallery = ({ AppCards }) => {
    const containerRef = useRef(null);
    const [isDragging, setDragging] = useState(false);
    const [startX, setStartX] = useState(null);

    const handleMouseDown = (e) => {
        setDragging(true);
        setStartX(e.clientX);
        if (!e.target.classList.contains('app-card-image') && !e.target.classList.contains('app-card-text')) {
            e.preventDefault();
        }
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;

        const offsetX = e.clientX - startX;
        if (containerRef.current) {
            containerRef.current.scrollLeft -= offsetX;
        }

        setStartX(e.clientX);
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    const handleClick = (targetId) => {
        const targetDiv = document.getElementById(targetId);

        if (targetDiv) {
            const scrollOffset = -100;
            const targetPosition = targetDiv.getBoundingClientRect().top + window.pageYOffset + scrollOffset;

            window.scrollTo({
                top: targetPosition,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div className='m-auto'>
            <div
                ref={containerRef}
                id="your-container-id"
                style={{
                    overflowX: 'auto',
                    WebkitOverflowScrolling: 'touch',
                    scrollbarWidth: 'thin',
                    scrollbarColor: 'transparent transparent'
                }}
                className='overflow-x-auto flex flex-row flex-nowrap scrollbar-hide py-10 px-4 md:p-10 no-scrollbar'
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
            >
                <div className='md:pl-10'></div>
                {AppCards.map((data, index) => (
                    <div key={index} className='inline-block mr-5' onClick={() => handleClick(data.h1)}>
                        <AppCard h1={data.h1} h2={data.h2} img1={data.img2} img2={data.img1} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AppGallery;
