import React from 'react';

import Slider from "react-slick"
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// IMPORT MEDIA
import image1 from '../../media/partners/logo-1.svg'
import image2 from '../../media/partners/logo-2.svg'
import image3 from '../../media/partners/logo-3.svg'
import image4 from '../../media/partners/logo-4.svg'
import image5 from '../../media/partners/logo-5.svg'
import image6 from '../../media/partners/logo-6.svg'
import image7 from '../../media/partners/logo-7.svg'
import image8 from '../../media/partners/logo-8.svg'
import image9 from '../../media/partners/logo-9.svg'
import image10 from '../../media/partners/logo-10.svg'
import image11 from '../../media/partners/logo-11.svg'
import image12 from '../../media/partners/logo-12.svg'
import image13 from '../../media/partners/logo-13.svg'
import image14 from '../../media/partners/logo-14.svg'
import image15 from '../../media/partners/logo-15.svg'
import image16 from '../../media/partners/logo-16.svg'
import image17 from '../../media/partners/logo-17.svg'
import image18 from '../../media/partners/logo-18.svg'
import image19 from '../../media/partners/logo-19.png'
import image20 from '../../media/partners/logo-20.svg'
import image21 from '../../media/partners/logo-21.svg'
import image22 from '../../media/partners/logo-22.png'
import image23 from '../../media/partners/logo-23.webp'
import image24 from '../../media/partners/logo-24.png'
import image25 from '../../media/partners/logo-25.png'
import image26 from '../../media/partners/logo-26.svg'
import image27 from '../../media/partners/logo-27.png'
import image28 from '../../media/partners/logo-28.png'

const PartnerSlider = () => {

    const partners = [
        {
            image: image1,
            title: '360 Enerji'
        },
        {
            image: image2,
            title: 'Astor Şarj'
        },
        {
            image: image3,
            title: 'Bilişim Vadisi'
        },
        {
            image: image4,
            title: 'Evbee'
        },
        {
            image: image5,
            title: 'Gio Enerji'
        },
        {
            image: image6,
            title: 'Oncharge'
        },
        {
            image: image7,
            title: 'Solution2Charge'
        },
        {
            image: image8,
            title: 'United Payment'
        },
        {
            image: image9,
            title: 'United Payment'
        },
        {
            image: image10,
            title: 'United Payment'
        },
        {
            image: image11,
            title: 'United Payment'
        },
        {
            image: image12,
            title: 'United Payment'
        },
        {
            image: image13,
            title: 'United Payment'
        },
        {
            image: image14,
            title: 'United Payment'
        },
        {
            image: image15,
            title: 'United Payment'
        },
        {
            image: image16,
            title: 'United Payment'
        },
        {
            image: image17,
            title: 'United Payment'
        },
        {
            image: image18,
            title: 'United Payment'
        },
        {
            image: image19,
            title: 'United Payment'
        },
        {
            image: image20,
            title: 'United Payment'
        },
        {
            image: image21,
            title: 'United Payment'
        },
        {
            image: image22,
            title: 'United Payment'
        },
        {
            image: image23,
            title: 'United Payment'
        },
        {
            image: image24,
            title: 'United Payment'
        },
        {
            image: image25,
            title: 'United Payment'
        },
        {
            image: image26,
            title: 'United Payment'
        },
        {
            image: image27,
            title: 'United Payment'
        },
        {
            image: image28,
            title: 'United Payment'
        }
    ]


    const settings = {
        style: { "height": "100%", "width": "100%", "display": "flex", "justify-content": "center", "align-items": "center", 'padding': '10px' },
        autoplay: true,
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        centerMode: true,
        centerPadding: '0px',
        responsive: [
            {
                breakpoint: 1224,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            }
        ]
    };

    return (
        <Slider {...settings}>
            {partners.map(partner => (
                <div key={partner.title} className='flex flex-row'>
                    <div className='flex flex-row justify-center items-center'>
                        <img className='w-20 h-20 md:w-40 md:h-40 object-contain' src={partner.image} alt='partner-logo'></img>
                    </div>
                </div>
            ))}
        </Slider>
    );
};

export default PartnerSlider;
