import React from 'react'
import { useParams } from "react-router-dom"

import { BsFillPersonFill, BsDot } from 'react-icons/bs'
// IMPORT DATA 
import { blogs } from '../data/blog'

// IMPORT COMPONENT
import Navbar from '../components/LandingBar'
import Footer from '../components/Footer'

const Blog = () => {

    const { param } = useParams()
    const blog = blogs.find(obj => obj.id === parseInt(param));

    return (
        <div className='overflow-x-hidden relative'>
            <div className='absolute w-full -z-50 bg-[#F3F7F9] h-full'></div>
            <Navbar />
            <div className='p-12 md:p-16'></div>
            <div className='text-3xl md:text-6xl font-bold md:text-center max-w-4xl m-auto p-5 text-[#0C0A3E]'>{blog.title}</div>
            <div className='flex flex-col md:flex-row md:items-center gap-5 p-10 justify-center'>
                <BsFillPersonFill size={25} />
                <div className='font-bold'>{blog.author}</div>
                <BsDot size={15} />
                <div className='text-slate-500'>{blog.date}</div>
                <BsDot size={15} />
                <div className='text-slate-500'>{blog.time} okuma</div>
            </div>
            <img className='max-w-screen-xl m-auto w-full h-[600px] object-cover' src={blog.img} alt=''></img>
            <div className='max-w-screen-xl m-auto text-xl p-5 xl:px-0 md:py-10 text-slate-500 leading-relaxed md:leading-loose'>{blog.text}</div>
            <Footer />
        </div>
    )
}

export default Blog