import React, { useState } from 'react';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const SolutionCard = ({ img, text }) => {
    const [hovered, setHovered] = useState(false);
    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };
    return (
        <div className='md:w-96 cursor-pointer rounded-2xl border-2 border-slate-200 flex flex-row md:flex-col'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <div className='overflow-hidden'>
                <img className={`object-contain w-36 h-24 md:h-96 md:w-96 duration-500 ${hovered ? 'scale-105' : ''}`} src={img}></img>
            </div>
            <div className='flex flex-row w-full justify-between items-center p-5 bg-white rounded-r-xl md:rounded-b-xl'>
                <div className='flex flex-col py-2'>
                    <div className='custom-font text-lg md:text-xl'>{text}</div>
                    <div className='custom-font-light text-md text-[#003B95]'>Keşfet</div>
                </div>
                <MdOutlineKeyboardArrowRight size={25} className={`duration-500 text-[#003B95] ${hovered ? 'opacity-100 ml-10' : ' opacity-0 mr-10'}`} />
            </div>
        </div>
    )
}

export default SolutionCard