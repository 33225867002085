import React, { useRef, useState } from 'react';

import Slider from "react-slick"
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// IMPORT MEDIA
import testimonial1 from '../../media/testimonials/01.png'
import testimonial2 from '../../media/testimonials/02.png'
import testimonial3 from '../../media/testimonials/03.png'
import testimonial4 from '../../media/testimonials/04.png'
import testimonial5 from '../../media/testimonials/05.png'
import testimonial6 from '../../media/testimonials/06.png'

// IMPORT BANNER
import banner1 from '../../media/banner-1.png'

// IMPORT CARD
import TestimonialCard from '../cards/TestimonialCard';

import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

import carImage1 from '../../media/car-megane.png'
import carImage2 from '../../media/car-modely.png'

import image1 from '../../media/testimonial-image-1.png'
import image2 from '../../media/testimonial-image-2.png'
import image3 from '../../media/testimonial-image-3.png'
import image4 from '../../media/testimonial-image-4.png'
import image5 from '../../media/testimonial-image-5.png'
import image6 from '../../media/testimonial-image-6.png'
import image7 from '../../media/testimonial-image-7.png'
import image8 from '../../media/testimonial-image-8.png'
import image9 from '../../media/testimonial-image-9.png'
import image10 from '../../media/testimonial-image-10.png'
import image11 from '../../media/testimonial-image-11.png'
import image12 from '../../media/testimonial-image-12.png'
import image13 from '../../media/testimonial-image-13.png'

const TestimonalSlider = () => {

    const containerRef = useRef(null);
    const [startX, setStartX] = useState(null);

    const handleMouseDown = (e) => {
        setStartX(e.clientX);
        if (!e.target.classList.contains('testimonial-card-image') && !e.target.classList.contains('testimonial-card-text')) {
            e.preventDefault();
        }
    };

    const handleMouseMove = (e) => {
        if (startX !== null) {
            const offsetX = e.clientX - startX;
            if (containerRef.current) {
                containerRef.current.scrollLeft -= offsetX;
            }
            setStartX(e.clientX);
        }
    };

    const handleMouseUp = () => {
        setStartX(null);
    };

    const testimonals = [
        {
            name: 'Rıza A****',
            carImage: carImage2,
            carText: 'Tesla Model Y Long Range * 250 kW',
            text: 'güzel bir sarj deneyimiydi. direkt ana yol üstünde olsa daha iyi olurdu ama böyle de iyi',
            image: [image8, image9, image10],
            date: '2024-01-24 | 09:34'
        },
        {
            name: 'Berke ****',
            carImage: carImage2,
            carText: 'Tesla Model Y Long Range * 250 kW',
            text: "Sarjı Lixhium üzerinden gerçekleştirdim. Ara tarafa güzel bir yıkamacı var, yukardaki tuvaletler çok temiz. Otopark 0-3 saat ücretsiz.",
            image: [image12, image13],
            date: '2024-01-25 | 16:50'
        },
        {
            name: 'Ahmet ****',
            carImage: carImage1,
            carText: 'Renault Megane E-Tech EV60 * 120 kW',
            text: '',
            image: [image1, image2],
            date: '2024-01-30 | 17:32'
        },
        {
            name: 'Sercan V****',
            carImage: carImage2,
            carText: 'Tesla Model Y Long Range * 250 kW',
            text: 'taze istasyon cok cok hizli',
            image: [image3, image4],
            date: '2024-01-01 | 20:10'
        },
        {
            name: 'Elif ****',
            carImage: carImage2,
            carText: 'Tesla Model Y Long Range * 250 kW',
            text: 'Sıkıntısız, sarj ederken zaman geçirmelik olanakları büyük +',
            image: [image5, image6, image7],
            date: '2024-12-27 | 09:39'
        },
        {
            name: 'Emre T****',
            carImage: carImage2,
            carText: 'Tesla Model Y Long Range * 250 kW',
            text: "Sabah saat 9:30'da geldim, iki istansyon da boştu. İstasyona ayırdıkları alan gayet geniş ve güzel. Tek sorun sarj hızı 55 kW'in üzerine çıkmadı. Yine de Trugo'ya teşekkürler.",
            image: [image11],
            date: '2024-12-27 | 09:39'
        },
        {
            name: 'Mevlut ****',
            carImage: carImage1,
            carText: 'Renault Megane E-Tech EV60 * 120 kW',
            text: 'Başarılı',
            image: [],
            date: '2024-01-21 | 12:45'
        }
    ];

    return (
        <div
            ref={containerRef}
            className='overflow-x-auto flex flex-row flex-nowrap scrollbar-hide no-scrollbar'
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
        >
            <div className='pl-5 md:pl-10'></div>
            {testimonals.map((testimonal, index) => (
                <div key={index} className='inline-block mr-5'>
                    <TestimonialCard
                        name={testimonal.name}
                        carImage={testimonal.carImage}
                        carText={testimonal.carText}
                        text={testimonal.text}
                        date={testimonal.date}
                        image={testimonal.image}
                    />
                </div>
            ))}
        </div>
    );
};

export default TestimonalSlider;
