import React from "react";
import YouTube from "react-youtube";

const YouTubeVideo = ({ videoUrl }) => {
    const getVideoId = (url) => {
        // Extract the video ID from the YouTube URL
        const regex = /(?:https?:\/\/)?(?:www\.)?youtu(?:\.be|be\.com)\/(?:watch\?v=|embed\/|v\/|\/)([^\s&]+)/;
        const match = url.match(regex);
        return match && match[1];
    };

    const videoId = getVideoId(videoUrl);

    if (!videoId) {
        return <div>Invalid YouTube URL</div>;
    }

    const isMobile = window.innerWidth <= 900; // Adjust the breakpoint as needed

    const opts = {
        height: isMobile ? "150" : "400", // Set the height for mobile and non-mobile devices
        width: isMobile ? "240" : "600", // Set the width for mobile and non-mobile devices
        playerVars: {
            // You can add additional player parameters here
        },
    };

    return (
        <div className="flex justify-center">
            <YouTube videoId={videoId} opts={opts} />
        </div>
    );
};

export default YouTubeVideo;
