import React from 'react'
import { useNavigate } from 'react-router-dom';
import { BsArrow90DegRight, BsForwardFill } from 'react-icons/bs'

// IMPORT COMPONENTS
import Navbar from '../../components/LandingBar'
import Footer from '../../components/Footer'

// IMPORT BANNER
import CarBanner3 from '../../media/car-banner-3.png'
import CarBanner4 from '../../media/car-banner-4.png'

// IMPORT LOGO
import RouteLogo2 from '../../media/route-logo-2.svg'
import TargetLogo from '../../media/target-logo-1.svg'
import StarLogo from '../../media/star-logo-1.svg'
import { BsFillLightningChargeFill } from 'react-icons/bs'

//IMPORT BUTTONS
import PrimaryButton from '../../components/buttons/PrimaryButton';
import FourthButton from '../../components/buttons/FourthButton';

//IMPORT HEADERS
import BigHeader from '../../components/headers/BigHeader';

const Automobiles = () => {
    const navigate = useNavigate();
    // HANDLE NAVIGATION
    const handleNavigation = (path) => {
        window.scrollTo(0, 0)
        navigate(path)
    }

    const handleMailSend = () => {
        const emailAddress = 'info@lixhium.com';
        window.location.href = `mailto:${emailAddress}`;
    }

    return (
        <div className='overflow-x-hidden relative'>
            <div className='absolute w-full h-full -z-50 bg-[#F3F7F9]'></div>
            <Navbar />
            <div className='p-16'></div>
            <BigHeader text={'Otomotiv Üreticileri'} />
            <div className='text-base md:text-lg text-slate-500 text-center max-w-3xl m-auto px-5 custom-font-light'>Elektrikli araçlarınız şu an ve gelecekteki araçlardır - bu nedenle kolay kullanılabilir ve sürdürülebilir olmalıdır! Bir otomobil üreticisi olarak, en iyi kullanici deneyimini sunmak sizin sorumluluğunuzdadır. Lixhium EV rota motoru tam da bunu yapmanıza yardımcı olur.</div>
            <div className='flex justify-center pt-5'>
                <div onClick={() => handleMailSend()} className='inline-block'>
                    <PrimaryButton text={'İletişime Geçin'} />
                </div>
            </div>
            <div className='flex justify-center'>
                <img className='absolute -z-20' src={CarBanner3} alt=''></img>
            </div>
            <div className='flex justify-center md:justify-end items-end h-full px-10 md:px-20 pt-20'>
                <div className='inline-block shadow-md relative rounded-xl border-2 border-[#CFFF04]'>
                    <div className='absolute w-full h-full bg-white opacity-90 md:opacity-75 -z-10 rounded-xl'></div>
                    <div className='p-5 md:p-10 flex flex-col max-w-2xl gap-10'>
                        <div className='flex flex-row gap-5 items-center'>
                            <BsForwardFill className='-rotate-90' size={25} />
                            <BsArrow90DegRight className='opacity-50' size={25} />
                        </div>
                        <div>
                            <div className='text-md text-slate-500 custom-font-light'>32 Km Düz Devam Edin</div>
                            <div className='text-xl text-bold custom-font'>Şarj - CCS - 250kW</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col-reverse md:flex-row max-w-screen-xl m-auto pt-10 sm:pt-20 md:pt-60 gap-10 md:gap-0'>
                <div className='w-full md:w-1/2 flex justify-center'>
                    <img className='max-w-xs md:max-w-md' src={CarBanner4} alt=''></img>
                </div>
                <div className='flex flex-col w-full md:w-1/2 p-5 md:p-10 pt-10 gap-5 max-w-xl'>
                    <div className='text-2xl md:text-3xl font-bold text-[#0C0A3E] custom-font'>Potansiyel müşterileri etkilemeyi sağla</div>

                    <div className='text-base md:text-xl text-slate-500 custom-font-light'>Menzil kaygısı, fiyattan sonra sürücülerin elektrikli araçlara geçmeme nedenlerinin başında gelir. Müşterileri elektrikli araç satın alma kararına kolayca yönlendirerek, onlara şarj istasyonları ve olanakları içeren elektrikli araçlar için özel rotalar gösterin.</div>
                    <div onClick={() => handleNavigation('/products/lixhium')} className='flex'>
                        <FourthButton text={"APP'mizi Keşfedin"} />
                    </div>
                </div>
            </div>
            <div className='flex flex-col lg:flex-row justify-around max-w-screen-xl m-auto pt-10 lg:pt-20 '>
                <div className='max-w-xl m-auto lg:m-0'>
                    <div className='text-2xl md:text-3xl font-bold p-5 lg:text-center text-[#0C0A3E] custom-font'>Son Kullanıcı Deneyimini Geliştirin</div>
                    <div className='border-2 border-[#CFFF04] rounded-2xl mx-5'></div>
                    <div className='text-base text-slate-500 lg:text-center max-w-2xl m-auto px-5 pt-5 custom-font-light'>Elektrikli araç yolculukları korkutucu olabilir. Nasıl, nerede veya ne zaman şarj etmeliyim? Yüksek kaliteli bir navigasyon uygulaması, tüm bu soruları yanıtlayarak müşterilerinizi güvenli ve sorunsuz bir yolculuğa yönlendirecektir.</div>
                    <div onClick={() => handleNavigation('/products/lixhium')} className='flex justify-center pt-5'>
                        <FourthButton text={"APP'imizi Keşfedin"} />
                    </div>
                </div>
                <div className='max-w-xl m-auto lg:m-0 pt-10 md:pt-0'>
                    <div className='text-2xl md:text-3xl font-bold p-5 lg:text-center text-[#0C0A3E] custom-font'>Satışları Artırın</div>
                    <div className='border-2 border-[#CFFF04] rounded-2xl mx-5'></div>
                    <div className='text-base text-slate-500 lg:text-center max-w-2xl m-auto px-5 pt-5 custom-font-light'>Güçlü EV yönlendirme uygulamalarıyla pazarlama gücünüzü artırın. Birden fazla temas noktasından sürücülere ulaşın. Satışları ve ek satışları artırın.</div>
                    <div onClick={() => handleMailSend()} className='flex justify-center pt-5'>
                        <FourthButton text={"İletişime Geçin"} />
                    </div>
                </div>
            </div>
            <div className='flex flex-col xl:flex-row gap-5 justify-between px-5 md:px-0 pt-10 xl:pt-20 max-w-screen-xl m-auto'>
                <div className='flex flex-col p-5 md:p-10 gap-5 shadow-md rounded-xl w-full max-w-xl xl:max-w-sm m-auto xl:m-0 border-2 border-[#F3F7F9] bg-white'>
                    <img className='h-12 w-12 self-center' src={TargetLogo} alt='truck-logo-2'></img>
                    <div className='text-base text-slate-500 text-start custom-font-light'>Özel olarak geliştirdiğimiz otomotiv modülü ile Android Automotive altyapısına sahip araçların infotainment sistemine built-in app olarak fabrika çıkışlı entegre edilebilmektedir.</div>
                </div>
                <BsFillLightningChargeFill className='text-[#CFFF04] self-center' size={25} />
                <div className='flex flex-col p-5 md:p-10 gap-5 shadow-md rounded-xl w-full max-w-xl xl:max-w-sm m-auto xl:m-0 border-2 border-[#F3F7F9] bg-white'>
                    <img className='h-12 w-12 self-center' src={StarLogo} alt='road-logo'></img>
                    <div className='text-base text-slate-500 text-start custom-font-light'>Elektrikli aracınızı OCPI, Plug & Charge protokollerine doğuştan entegre hale getirin.</div>
                </div>
                <BsFillLightningChargeFill className='text-[#CFFF04] self-center' size={25} />
                <div className='flex flex-col p-5 md:p-10 gap-5 shadow-md rounded-xl w-full max-w-xl xl:max-w-sm m-auto xl:m-0 border-2 border-[#F3F7F9] bg-white'>
                    <img className='h-12 w-12 self-center' src={RouteLogo2} alt='graph-logo-2'></img>
                    <div className='text-base text-slate-500 text-start custom-font-light'>Entegre edilmiş EV ve şarj istasyonu veritabanıyla akıllı yönlendirme motoru.</div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Automobiles