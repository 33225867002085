import React from 'react'
import { useNavigate } from 'react-router-dom';
import { BsArrow90DegRight, BsForwardFill, BsDot } from 'react-icons/bs'

// IMPORT COMPONENTS
import Navbar from '../../components/LandingBar'
import Footer from '../../components/Footer'

// IMPORT BANNER
import ChargeBanner from '../../media/charge-banner-1.png'
import ChargeBanner2 from '../../media/charge-banner-2.png'
import ChargeBanner3 from '../../media/charge-banner-3.png'
import AppBanner3 from '../../media/cpo-app-banner-1.png'

// IMPORT LOGO
import LixhiumLogo from '../../media/lixhium-logo-black.svg'
import StartsLogo from '../../media/starts-logo-1.png'
import HeartLogo from '../../media/heart-logo-1.svg'

import CpoImage1 from '../../media/cpo-icon-1.svg'
import CpoImage2 from '../../media/cpo-icon-2.svg'
import CpoImage3 from '../../media/cpo-icon-3.svg'

//IMPORT BUTTONS
import PrimaryButton from '../../components/buttons/PrimaryButton';
import FourthButton from '../../components/buttons/FourthButton';

//IMPORT HEADERS
import HeaderBig from '../../components/headers/BigHeader'
import HeaderSmall from '../../components/headers/SmallHeader'

const Cpo = () => {
    const navigate = useNavigate();

    // HANDLE NAVIGATION
    const handleNavigation = (path) => {
        window.scrollTo(0, 0)
        navigate(path)
    }

    const handleMailSend = () => {
        const emailAddress = 'info@lixhium.com';
        window.location.href = `mailto:${emailAddress}`;
    }

    return (
        <div className='overflow-x-hidden relative'>
            <div className='absolute w-full h-full -z-50 bg-[#F3F7F9]'></div>
            <Navbar />
            <div className='p-16'></div>
            <HeaderBig text={'CPO & eMSP'} />
            <HeaderSmall text={'40.000+ elektrikli araç kullanıcısına anında erişin.'} />
            <div className='py-5 md:pt-5 flex justify-center'>
                <div onClick={() => handleMailSend()} className=' inline-block'>
                    <PrimaryButton text={'Entegrasyon için İletişime Geçin'} />
                </div>
            </div>
            <div className='flex justify-center'>
                <img className='absolute -z-20 ' src={ChargeBanner} alt='charge-banner-1'></img>
            </div>
            <div className='flex justify-end px-5 md:px-10 pt-5 max-w-screen-xl m-auto'>
                <div className='inline-block shadow-md relative rounded-xl border-2 border-[#F3F7F9] md:hover:scale-105 duration-500'>
                    <div className='absolute w-full h-full bg-white opacity-90 -z-10 rounded-xl'></div>
                    <div className='p-5 md:p-10 flex flex-col gap-5 max-w-xl'>
                        <img className='h-10 w-10 self-center' src={CpoImage1} alt='route-logo'></img>
                        <div>
                            <div className='text-lg custom-font'>Geliri Artırın</div>
                            <div className='text-md custom-font-light'>İstasyonlarınız Lixhium haritasına dahil edin.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-center px-5 md:px-10 pt-5 md:pt-10 max-w-screen-xl m-auto'>
                <div className='inline-block shadow-md relative rounded-xl border-2 border-[#F3F7F9] md:hover:scale-105 duration-500'>
                    <div className='absolute w-full h-full bg-white opacity-90 -z-10 rounded-xl'></div>
                    <div className='p-5 md:p-10 flex flex-col gap-5 max-w-xl'>
                        <img className='h-10 w-10 self-center' src={CpoImage2} alt='graph-logo'></img>
                        <div>
                            <div className='text-lg custom-font'>Kullanıcı Deneyimini Geliştirin</div>
                            <div className='text-md custom-font-light'>Sadakat programına katılın.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-start px-5 md:px-10 pt-5 md:pt-10 max-w-screen-xl m-auto'>
                <div className='inline-block shadow-md relative rounded-xl border-2 border-[#F3F7F9] md:hover:scale-105 duration-500'>
                    <div className='absolute w-full h-full bg-white opacity-90 -z-10 rounded-xl'></div>
                    <div className='p-5 md:p-10 flex flex-col gap-5 max-w-xl'>
                        <img className='h-10 w-10 self-center' src={CpoImage3} alt='battery-logo'></img>
                        <div>
                            <div className='text-lg custom-font'>Sektör Hakimiyetinizi Artırın</div>
                            <div className='text-md custom-font-light'>Pazarlama ve kampanyalarınızı yönetin.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col lg:flex-row max-w-screen-xl m-auto pt-10 lg:pt-40 gap-10 md:gap-0'>
                <div className='flex flex-col w-full lg:w-1/2 p-5 md:p-10 gap-5 max-w-xl custom-font-light'>
                    <div className='text-2xl font-bold text-[#0C0A3E] custom-font'>Geliri Artırın</div>
                    <div className='text-lg md:text-xl text-slate-500' >Kendi iş kurallarınızı Lixhium algoritmasına dahil edin ve sürücüleri şarj istasyonlarınıza yönlendirin.</div>
                    <div className='flex flex-row item-center text-slate-500'>
                        <BsDot size={25} className='flex-shrink-0' />
                        <div>Anında pazar penetrasyonu sağlayın.</div>
                    </div>
                    <div className='flex flex-row item-center text-slate-500 flex-shrink-0'>
                        <BsDot size={25} className='flex-shrink-0' />
                        <div>Birinci günden 40.000'i aşkın kullanıcıya erişin.</div>
                    </div>
                    <div className='flex flex-row item-center text-slate-500 flex-shrink-0'>
                        <BsDot size={25} className='flex-shrink-0' />
                        <div>Pazarlama bütçenizi doğru ve gerçek kullanıcılara ulaşmaya ayırın.</div>
                    </div>
                    <div className='flex flex-row item-center text-slate-500 flex-shrink-0'>
                        <BsDot size={25} className='flex-shrink-0' />
                        <div>Yerleşik sadakat programına katılın.</div>
                    </div>
                    <div className='flex flex-row item-center text-slate-500 flex-shrink-0'>
                        <BsDot size={25} className='flex-shrink-0' />
                        <div>Rakiplerinizle aynı pazar yerinde rekabet edin.</div>
                    </div>
                    <div className='flex'>
                        <div onClick={() => handleNavigation('/products/api')} className='inline-block'>
                            <PrimaryButton text={"API'mızı Keşfedin"} />
                        </div>
                    </div>
                </div>

                <div className='w-full lg:w-1/2'>
                    <div className='flex justify-center'>
                        <img className='absolute max-w-sm sm:max-w-lg -z-20 px-5' src={ChargeBanner3} alt='car-banner-2'></img>
                    </div>
                    <div className='flex justify-center lg:justify-end items-end h-full'>
                        <div className='inline-block shadow-md relative rounded-xl border-2 border-[#F3F7F9]'>
                            <div className='absolute w-full h-full bg-white opacity-90 -z-10 rounded-lg'></div>
                            <div className='p-10 flex flex-col max-w-2xl gap-5'>
                                <div className='flex flex-row gap-5 items-center'>
                                    <BsForwardFill className='-rotate-90 opacity-50' size={25} />
                                    <BsForwardFill className='-rotate-90' size={25} />
                                    <BsArrow90DegRight className='opacity-50' size={25} />
                                </div>
                                <div>
                                    <div className='text-md text-slate-500 custom-font-light'>Ulaşmanıza 400 metre kaldı</div>
                                    <div className='text-xl text-bold custom-font'>Super Şarj İstasyonu</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex flex-col md:flex-row max-w-screen-xl m-auto pt-20 md:pt-60'>
                <div className='w-48 md:w-1/2 flex justify-center m-auto'>
                    <img className='md:max-w-xs' src={AppBanner3} alt=''></img>
                </div>
                <div className='flex flex-col w-full md:w-1/2 gap-5 max-w-xl relative justify-center'>
                    <div className='px-5 md:p-10 flex flex-col gap-2 md:border-2 md:border-[#F3F7F9] rounded-md md:shadow-md bg-white py-10 mt-10 md:mt-0'>
                        <div className='hidden md:flex flex-row gap-2'>
                            <div className='p-2 w-24 text-xs bg-[#0C0A3E] rounded-xl text-[#CFFF04] text-center'>Web</div>
                            <div className='p-2 w-24 text-xs bg-[#0C0A3E] rounded-xl text-[#CFFF04] text-center'>iOS</div>
                            <div className='p-2 w-24 text-xs bg-[#0C0A3E] rounded-xl text-[#CFFF04] text-center'>Android</div>
                        </div>
                        <div className='text-2xl md:text-3xl font-bold text-[#0C0A3E] custom-font'>Son kullanıcı deneyimini geliştirin</div>
                        <div className='text-lg md:text-xl text-slate-500 custom-font-light pt-5'>Akıllı ve sorunsuz bir EV sürücü platformuna dahil olun, istasyonlarınızı rotalama ve ödeme gibi hizmetlerimiz ile entegre edin.</div>
                        <div onClick={() => handleNavigation('/products/lixhium')} className='flex'>
                            <FourthButton text={"APP'imizi Keşfedin"} />
                        </div>
                    </div>
                </div>
            </div>


            <div className='text-2xl md:text-3xl font-bold md:text-center pt-10 md:pt-40 px-5 text-[#0C0A3E] custom-font'>Sektör hakimiyetinizi artırın</div>
            <div className='text-lg md:text-xl text-slate-500 md:text-center pt-5 max-w-xl md:m-auto px-5 custom-font-light'> B2C ve B2B müşteriler arasında en iyi e-mobilite hizmet sağlayıcısı olma konusunda avantaj elde edin.</div>
            <div onClick={() => handleMailSend()} className='flex justify-center pt-4 '>
                <PrimaryButton text={"İletişime Geçin"} />
            </div>
            <div className='flex justify-center'>
                <img className='absolute -z-20 scale-75' src={ChargeBanner2} alt=''></img>
            </div>

            <div className='flex justify-center md:justify-start px-10 md:px-20 pt-8 md:pt-12 max-w-screen-xl m-auto'>
                <div className='flex justify-start px-20'>
                    <div className='inline-block shadow-md relative rounded-xl border-2 border-[#F3F7F9]'>
                        <div className='absolute w-full h-full bg-white opacity-90 -z-10 rounded-lg'></div>
                        <div className='flex flex-col gap-5 max-w-xl'>
                            <img className='self-center max-w-[200px] md:max-w-xs' src={StartsLogo} alt='battery-logo'></img>
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex justify-center px-10 md:px-20 pt-8 md:pt-12'>
                <div className='flex justify-start px-20'>
                    <div className='inline-block shadow-md relative rounded-xl border-2 border-[#F3F7F9]'>
                        <div className='absolute w-full h-full bg-white opacity-90 -z-10 rounded-lg'></div>
                        <div className='flex flex-col gap-5 max-w-xl p-10'>
                            <img className='self-center w-10 h-10 md:w-16 md:h-16' src={LixhiumLogo} alt='battery-logo'></img>
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex justify-center md:justify-end px-10 md:px-20 pt-8 md:pt-12 max-w-screen-xl m-auto'>
                <div className='flex justify-start px-20'>
                    <div className='inline-block shadow-md relative rounded-xl border-2 border-[#F3F7F9]'>
                        <div className='absolute w-full h-full bg-white opacity-90 -z-10 rounded-lg'></div>
                        <div className='flex flex-col gap-5 max-w-xl p-10'>
                            <img className='self-center h-10 w-10 md:w-16 md:h-16' src={HeartLogo} alt='battery-logo'></img>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Cpo