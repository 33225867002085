import React, { useEffect, useState, useRef } from 'react'

import AppPhoto1 from '../../media/main-phone-banner-1.png'
import AppPhoto2 from '../../media/main-phone-banner-2.png'
import AppPhoto3 from '../../media/main-phone-banner-3.png'

const images = [AppPhoto1, AppPhoto2, AppPhoto3]

const AppSlide = ({ mirror }) => {
    const [imageIndex, setImageIndex] = useState(0)
    useEffect(() => {
        const interval = setInterval(() => {
            setImageIndex((imageIndex + 1) % images.length);
        }, 2000);
        return () => clearInterval(interval);
    }, [imageIndex]);

    return (
        <div className={`${mirror ? 'mirror-y' : ''} md:w-3/5 flex flex-col m-auto`}>
            <img className='w-1/3 md:w-3/4 flex m-auto' src={images[imageIndex]} alt='api-banner-1'></img>
        </div>
    )
}

export default AppSlide