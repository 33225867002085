import { Routes, Route } from "react-router-dom"

// IMPORT PAGES
import Landing from './pages/Landing'
import About from './pages/About'
import Blogs from './pages/Blogs'
import Blog from './pages/Blog'
import Blog2 from './pages/Blog2'
import Cpo from './pages/solutions/Cpo'
import Fleets from './pages/solutions/Fleets'
import Automobiles from "./pages/solutions/Automobiles"

import Lixhium from './pages/products/Lixhium'
import Api from './pages/products/Api'

import Ads from './pages/services/Ads'
import Consult from './pages/services/Consult'
import Report from './pages/services/Report'

import Deneme from "./pages/Deneme"

function App() {

  return (
    <Routes>
      <Route path="/" element={<Landing />}></Route>
      <Route path="/about" element={<About />} ></Route>
      <Route path="/products/Lixhium" element={<Lixhium />}></Route>
      <Route path="/products/api" element={<Api />}></Route>
      <Route path="/solutions/cpo&emsp" element={<Cpo />}></Route>
      <Route path="/solutions/fleets" element={<Fleets />}></Route>
      <Route path="/solutions/automobiles" element={<Automobiles />}></Route>
      <Route path="/services/consult" element={<Consult />}></Route>
      <Route path="/services/report" element={<Report />}></Route>
      <Route path="/services/ads" element={<Ads />}></Route>
      <Route path="/blogs" element={<Blogs />}></Route>
      <Route path="/blogs/:param" element={<Blog />}></Route>
      <Route path="/blogs2/:param" element={<Blog2 />}></Route>
      <Route path="/deneme" element={<Deneme />}></Route>
    </Routes>
  );
}

export default App;
