import React from 'react'

const SecondaryButton = ({ image, title, text }) => {
    return (
        <div className='flex flex-row w-full gap-5 py-2 px-4 bg-[#003B95] text-white md:text-[#0C0A3E] md:bg-transparent md:hover:bg-[#CFFF04] duration-500 cursor-pointer items-center text-xs md:text-sm rounded-md'>
            <img src={image} className='h-8 w-8' alt='lixhium-logo'></img>
            <div className='flex flex-col'>
                <div className='font-bold custom-font'>{title}</div>
                <div className='text-black/50'>{text}</div>
            </div>
        </div>
    )
}

export default SecondaryButton