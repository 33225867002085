import React from 'react'

const HeaderTag = ({ AppCards }) => {
    return (
        <div className='max-w-screen-2xl m-auto'>
            <div id={AppCards.h1} className='w-[500px] md:rounded-2xl relative md:hover:scale-105 duration-500 overflow-hidden md:-ml-20 cursor-none  '>
                <img className='absolute md:rounded-2xl h-full w-full -z-20 object-cover' src={AppCards.img2}></img>
                <div className='flex flex-col p-6 md:p-8 custom-font text-white overflow-hidden w-full items-start md:items-end'>
                    <div className='text-md md:text-lg custom-font-light'>{AppCards.h1}</div>
                    <div className='text-xl md:text-2xl'>{AppCards.h2}</div>
                </div>
            </div>
        </div>

    )
}

export default HeaderTag