import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { AiOutlineRight } from 'react-icons/ai'

// IMPORT COMPONENTS
import Navbar from '../components/LandingBar'

import Footer from '../components/Footer'
import AppsBanner from '../components/banners/AppsBanner'
import PartnerSlider from '../components/sliders/PartnerSlider'
import YouTubeVideo from '../components/YoutubeVideo'

// IMPORT VIDEO
import AppVideoContent from '../media/app-video.mp4'

// IMPORT LOGO
import ChargerLogo from '../media/charger-logo-black.svg'
import BuildingLogo from '../media/building-logo-custom.svg'
import LixhiumLogo from '../media/lixhium-logo-black.svg'
import PlugLogo from '../media/plug-logo-custom.svg'
import TruckLogo from '../media/truck-logo-3-black.svg'
import CarLogo from '../media/car-logo-2-black.svg'

// IMPORT BANNER
import ApiBanner from '../media/api-banner-1.png'
import AppBanner from '../media/app-banner-1.png'

import AppPhoto1 from '../media/main-phone-banner-1.png'
import AppPhoto2 from '../media/main-phone-banner-2.png'
import AppPhoto3 from '../media/main-phone-banner-3.png'
import PrimaryButton from '../components/buttons/PrimaryButton';
import ThirdButton from '../components/buttons/ThirdButton';
import FourthButton from '../components/buttons/FourthButton';

const images = [AppPhoto1, AppPhoto2, AppPhoto3]

const About = () => {

    const navigate = useNavigate();
    const [imageIndex, setImageIndex] = useState(0)
    const [solution1, setSolution1] = useState(false)
    const [solution2, setSolution2] = useState(false)
    const [solution3, setSolution3] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => {
            setImageIndex((imageIndex + 1) % images.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [imageIndex]);

    // HOVER SOLUTION
    const handleMouseEnterSolution1 = () => {
        setSolution1(true);
    };

    const handleMouseLeaveSolution1 = () => {
        setSolution1(false);
    };

    const handleMouseEnterSolution2 = () => {
        setSolution2(true);
    };

    const handleMouseLeaveSolution2 = () => {
        setSolution2(false);
    };

    const handleMouseEnterSolution3 = () => {
        setSolution3(true);
    };

    const handleMouseLeaveSolution3 = () => {
        setSolution3(false);
    };

    // HANDLE NAVIGATION
    const handleNavigation = (path) => {
        window.scrollTo(0, 0)
        navigate(path)
    }

    return (
        <div className='overflow-x-hidden'>
            <Navbar />
            <div className='p-12 md:p-16'></div>
            <div className='text-3xl md:text-6xl font-bold text-center max-w-4xl md:max-w-2xl m-auto pt-5 pb-2 text-[#0C0A3E] custom-font-bold'>Tüm Şarj İstasyonları</div>
            <div className='text-3xl md:text-6xl font-bold text-center max-w-4xl md:max-w-2xl pb-5 m-auto text-[#0C0A3E] custom-font-bold'>Tek Uygulama</div>
            <div className='flex flex-col-reverse md:flex-row-reverse max-w-screen-xl w-full m-auto p-5 gap-10 md:gap-0 md:p-10'>
                <div className='md:w-1/2 w-full flex flex-col gap-2 md:gap-5 justify-center'>
                    <div className='text-2xl md:text-3xl font-bold text-[#0C0A3E] custom-font'>Lixhium</div>
                    <div className='flex flex-col max-w-md gap-5 text-slate-500 text-lg md:text-xl'>
                        {/* <div className='text-base md:text-lg'>Lixhium, elektrikli araç şarj ekosistemini tek bir platformda toplayan, lisanslı şarj istasyonu işletmenleri ile elektrikli araç kullanıcılarını bir araya getiren, tüm istasyonların aynı haritada gösterilmesi ile birlikte; navigasyon, akıllı yol planlama, şarj başlatma, check-in / yorum yapma, ödeme, dijital cüzdan, puan toplama, sadakat programları, tarife karşılaştırma, araca uygun şarj miktarı ve ücreti hesaplama ve mobil şarj hizmeti gibi pek çok işe yarar özellikleri bulunan 'tarafsız' bir pazar yeridir.</div> */}
                        <div className='text-base md:text-lg'>Lixhium, elektrikli araç kullanıcılarının günlük yaşamlarını kolaylaştırmayı hedeflerken birçok sorununa tek bir platformdan çözüm sağlayan elektrikli araç şarj istasyonu işletmenlerini ve EV kullanıcılarını bir araya getiren hazır sadakat programları sağlayan mobil uygulama tabanlı 'tarafsız' bir ortak pazar yeridir.</div>
                        <div onClick={() => handleNavigation('/products/api')} className='flex'>
                            <PrimaryButton text={"App'i Keşfet"} />
                        </div>
                    </div>
                </div>
                <div className='md:w-1/2 hidden md:flex flex-col gap-5 justify-start m-auto'>
                    <img className='max-w-[150px] md:max-w-[250px] flex m-auto' src={images[imageIndex]} alt='api-banner-1'></img>
                </div>
            </div>
            <div className='text-2xl md:text-3xl lg:text-4xl font-bold p-5 md:pt-10 text-start md:text-center max-w-5xl m-auto text-[#0C0A3E] custom-font'>Şirketlere Büyük Ölçekte Elektrifikasyon Konusunda Yardımcı Oluyoruz</div>
            <div className='text-base md:text-xl text-start md:text-center max-w-4xl m-auto text-slate-500 px-5'>Lixhium, hem bireysel sürücüler hem de filolar için elektrikli mobiliteye geçişi kolay ve maliyet etkin hale getirir. Lixhium, dünyanın önde gelen menzil tahmini ve elektrikli araç rotalama platformudur. Herhangi bir marka veya model elektrikli araç için enerji tüketimini ve şarj sürelerini, dünya genelinde herhangi bir rotada doğru bir şekilde tahmin ederiz.</div>
            <div className="max-w-4xl h-[450px] m-auto px-5 p-10">
                <video autoPlay playsInline loop muted
                    style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                        top: "0",
                        left: "0",
                        zIndex: -1,
                        borderRadius: '2rem'
                    }}>
                    <source src={AppVideoContent} type='video/mp4' ></source>
                </video>
            </div>

            <div className='flex flex-col md:flex-row justify-center items-center pt-0 p-5 md:p-10 gap-5 md:gap-10'>

                <div className='flex flex-row p-6 md:p-10 gap-5 rounded-2xl shadow-md max-w-md w-full border-2 border-[#CFFF04]'>
                    <img className='h-10 w-10 md:h-14 md:w-14' src={ChargerLogo} alt='charger-logo'></img>
                    <div className='flex flex-col justify-center text-sm md:text-md'>
                        <div className='font-bold text-[#0C0A3E]'>Şarj İstasyonu</div>
                        <div>6000+</div>
                    </div>
                </div>

                <div className='h-12 hidden md:flex border-2 border-[#CFFF04]'></div>
                <div className='flex flex-row p-6 md:p-10 gap-5 rounded-2xl shadow-md max-w-md w-full border-2 border-[#CFFF04]'>
                    <img className='h-10 w-10 md:h-14 md:w-14' src={BuildingLogo} alt='building-logo'></img>
                    <div className='flex flex-col justify-center text-sm md:text-md'>
                        <div className='font-bold text-[#0C0A3E]'>Sarj İstasyonu İşletme Ağı</div>
                        <div>20+</div>
                    </div>
                </div>
            </div>

            <div className='text-2xl md:text-3xl font-bold text-start md:text-center p-5 md:p-10 text-[#0C0A3E] custom-font'>Tahmin Edilebilir E-Mobilite Rotalama</div>
            <div className='flex flex-col gap-2 md:gap-5 max-w-4xl m-auto text-start md:text-center text-base md:text-xl text-slate-500 px-5'>
                <div>Lixhium, EV'ler için özel olarak hesaplanan rotaları sağlar. A'dan B'ye olan rotanızı, yol boyunca optimal şarj istasyonlarını da içeren dinamik değişkenlerle hesaplarız! Sürücülerin varış noktasına ulaşmak için yeterli şarja sahip olacaklarından emin oluyoruz.</div>
                <div>Rotalama motorumuz, gerçek zamanlı araç menzilini hesaplamak için on beş farklı değişken kullanır. Bunlar arasında şarj durumu, hava durumu, ağırlık, duraklar, olanaklar, yol eğimi, yükselti, soket türü, şarj hızı, bekleme süresi, ödeme seçenekleri, enerji maliyetleri, batarya sağlığı, sürüş tarzı ve daha fazlası bulunur!</div>
                <div>Platformumuzu, mevcut sistemlere entegre edilebilen API tabanlı bir SaaS çözümü olarak veya güçlü bir ön yüz olarak sunuyoruz.</div>
            </div>

            <div className='flex flex-col md:flex-row justify-center items-center p-5 gap-5 md:gap-10 pt-10'>
                <div className='flex flex-row p-6 md:p-10 gap-5 rounded-2xl shadow-md max-w-md w-full border-2 border-[#CFFF04]'>
                    <img className='h-10 w-10 md:h-14 md:w-14' src={LixhiumLogo} alt='charger-logo'></img>
                    <div className='flex flex-col'>
                        <div className='font-bold justify-center text-sm md:text-md text-[#0C0A3E]'>Şarj Hızı</div>
                        <div className='text-slate-800 text-xs md:text-base'>150 kW</div>
                    </div>
                </div>
                <div className='h-12 hidden md:flex border-2 border-[#CFFF04]'></div>
                <div className='flex flex-row p-6 md:p-10 gap-5 rounded-2xl shadow-md max-w-md w-full border-2 border-[#CFFF04]'>
                    <img className='h-10 w-10 md:h-14 md:w-14' src={PlugLogo} alt='building-logo'></img>
                    <div className='flex flex-col'>
                        <div className='font-bold justify-center text-sm md:text-md text-[#0C0A3E]'>Soket</div>
                        <div className='text-slate-800 text-xs md:text-base'>CCS 2</div>
                    </div>
                </div>
            </div>
            <div className='text-2xl md:text-3xl font-bold text-center p-5 md:p-10 text-[#0C0A3E] custom-font'>Çözümler</div>
            <div className='text-center text-base md:text-xl text-slate-500 max-w-2xl m-auto px-5'>Farklı endüstrilere özel olarak uyarlanmıştır. Şirketiniz için Lixhium'un neler yapabileceğini keşfedin.</div>
            <div className='flex flex-col p-5 md:p-10 justify-between max-w-md md:max-w-xl lg:max-w-2xl w-full m-auto gap-5'>
                <div onClick={() => handleNavigation('/solutions/cpo&emsp')} className='border-2 border-[#CFFF04] shadow-lg'>
                    <ThirdButton title={'CPO & eMSP'} text={'Keşfet'} image={ChargerLogo} />
                </div>
                <div onClick={() => handleNavigation('/solutions/automobiles')} className='border-2 border-[#CFFF04] shadow-lg'>
                    <ThirdButton title={'Otomotiv'} text={'Keşfet'} image={CarLogo} />
                </div>
                <div onClick={() => handleNavigation('/solutions/fleets')} className='border-2 border-[#CFFF04] shadow-lg'>
                    <ThirdButton title={'Filolar'} text={'Keşfet'} image={TruckLogo} />
                </div>

            </div>
            <div className='flex flex-col md:flex-row pt-5 gap-5 justify-center max-w-screen-xl m-auto'>
                <YouTubeVideo videoUrl={'https://www.youtube.com/watch?v=wslA8oZuV6c&ab_channel=Lixhium'} />
                <YouTubeVideo videoUrl={'https://www.youtube.com/watch?v=EY7ddAO-8bY'} />
            </div>
            <div className='flex flex-col xl:flex-row-reverse max-w-screen-xl m-auto w-full p-5 md:p-20 gap-10'>
                <div className='w-full xl:w-1/2 flex flex-col gap-5 justify-center'>
                    <div className='text-3xl font-bold text-[#0C0A3E custom-font'>Güçlü araçlar takımı</div>
                    <div className=' flex flex-col xl:max-w-md gap-5 text-slate-500 text-base md:text-base text-start'>
                        <div>Çekirdek olarak EV özel rota planlamasıyla hızla üretim için hazır mobilite uygulamaları oluşturun. İç içe geçmiş bir GraphQL arayüzü içinden EV model veritabanımızı, OCPI uyumlu istasyon veritabanını ve rota planlama motorunu kullanın. Rota algoritmalarımızı iş kurallarınızla tamamen uyumlu hale getirerek özelleştirin</div>
                        <div>Eski telematik sistemlerini özelleştirmek veya enerji tüketimi modellerini araştırmaktan kaçının. Lixhium, Elektrikli Mobilite filo sahiplerinin ve Elektrikli Mobilite Hizmet Sağlayıcılarının gerçek potansiyelini ortaya çıkarmanıza yardımcı olur.</div>
                        <div onClick={() => handleNavigation('/products/api')} className='flex'>
                            <FourthButton text={'API Genel Bakış'} />
                        </div>
                    </div>
                </div>
                <img className='w-full xl:w-1/2 max-w-xl m-auto xl:max-w-none' src={ApiBanner} alt='api-banner-1'></img>
            </div>

            <div className='text-3xl font-bold text-center p-10 text-[#0C0A3E] custom-font'>Partnerler</div>
            <div className='max-w-screen-2xl m-auto'>
                <PartnerSlider />
            </div>
            <Footer />
        </div>
    )
}

export default About